import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, Title, Input, Button, LargeImageUpload, LangTabs, Select } from '../../Components'
import { Fonts, Colors } from '../../Utils/theme'
import get from 'lodash/get'
import { GOOGLE_MAPS_API_KEY } from '../../Constants'
import CouponMultiSelect from './CouponMultiselect'

const MAP_HEIGHT = 300
const MAP_WIDTH = 630

const useStyles = makeStyles((theme) => ({
  column: {
    flex: 1
  },
  spacer: {
    width: '2rem'
  },
  coordsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  coordsButton: {
    marginTop: '.125rem',
    marginLeft: '1.5rem',
    height: '3.5rem'
  },
  mapsPlaceholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: Colors.cardBackgroundColor,
    color: Colors.content,
    height: MAP_HEIGHT,
    width: MAP_WIDTH,
    marginBottom: '1.5rem'
  },
  mapContainer: {
    marginBottom: '1.5rem'
  },
  coordInput: {
    width: '100%',
  },
  contactRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  phoneInputContainer: {
    flex: 2
  },
  emailInputContainer: {
    flex: 3
  },
  inputLabel: {
    fontFamily: Fonts.content,
    color: Colors.content,
    fontSize: '1.125rem',
    marginBottom: '.25rem'
  },
  alwaysVisibleContainer: {
    paddingBottom: '2rem'
  },
  description: {
    fontSize: '1.125rem',
    fontWeight: 300,
    fontFamily: Fonts.content,
    color: Colors.content,
    marginBottom: '.25rem'
  },
  secondHandSpacer: {
    height: '1rem'
  }
}))

const CouponOrganizationForm = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [langTab, setLangTab] = useState('fi')

  const handleSetLatitude = (value) => {
    props.setLatitude(value.replace(',', '.').replace(/[^0-9.]/g, ''))
  }
  const handleSetLongitude = (value) => {
    props.setLongitude(value.replace(',', '.').replace(/[^0-9.]/g, ''))
  }

  const getCoordinates = () => {
    const { address } = props
    const API_URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`
    try {
      window.fetch(API_URL)
        .then((raw) => raw.json())
        .then((response) => {
          const newLat = get(response, 'results[0].geometry.location.lat')
          const newLon = get(response, 'results[0].geometry.location.lng')
          if (newLat && newLon) {
            handleSetLatitude(newLat.toString())
            handleSetLongitude(newLon.toString())
          }
        })
    } catch (error) {
      console.log(error)
    }
  }

  const renderMap = () => {
    if (!props.latitude || !props.longitude) {
      return (
        <div className={classes.mapsPlaceholder}>
          <p>{t('enter_coordinates_to_display_map')}</p>
        </div>
      )
    }
    return (
      <iframe
        src={`https://maps.google.com/maps?q=${props.latitude},${props.longitude}&hl=fi&z=10&output=embed`}
        width={MAP_WIDTH}
        height={MAP_HEIGHT}
        frameBorder={0}
        style={{ border: 0 }}
        aria-hidden='false'
        tabIndex={0}
        title='map'
      />
    )
  }

  const getCategoryOptions = () => (props.categories || []).map(cat => ({
    value: cat.id,
    label: cat.titleFi || cat.titleEn
  }))

  const renderLangContent = () => {
    if (langTab === 'en') {
      return (
        <Input
          label={t('description')}
          value={props.contentEn}
          onChange={props.setContentEn}
          multiline
        />
      )
    }
    return (
      <Input
        label={t('description')}
        value={props.contentFi}
        onChange={props.setContentFi}
        multiline
      />
    )
  }

  const getStatus = (lang) => {
    if (lang === 'en') {
      return props.contentEn ? 'green' : 'red'
    } else {
      // Fi
      return props.contentFi ? 'green' : 'red'
    }
  }

  const renderContentInput = () => {
    return (
      <LangTabs
        currentLanguage={langTab}
        setLanguage={setLangTab}
        content={renderLangContent()}
        statusFi={getStatus('fi')}
        statusEn={getStatus('en')}
      />
    )
  }

  const renderAlwaysVisible = () => {
    return (
      <div className={classes.alwaysVisibleContainer}>
        <div className={classes.inputLabel}>{t('always_visible')}</div>
        <div className={classes.description}>{t('always_visible_description')}</div>
        <div>
          <Checkbox
            checked={props.alwaysVisible}
            onChange={() => props.setAlwaysVisible(true)}
            label={t('yes')}
          />
          <Checkbox
            checked={!props.alwaysVisible}
            onChange={() => props.setAlwaysVisible(false)}
            label={t('no')}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <Title title={t('organization_details')} description={t('organization_details_description')} />
      <div>
        {renderAlwaysVisible()}
        <LargeImageUpload
          photo={props.rawPhoto || props.photo}
          removePhoto={props.removePhoto}
          onFileUpload={props.setPhoto}
        />
        <div className={classes.inputLabel}>{t('second_hand')}</div>
        <Checkbox
          checked={props.secondHand}
          onChange={() => props.setSecondHand(!props.secondHand)}
          label={t('is_second_hand')}
        />
        <div className={classes.secondHandSpacer} />
        <Input
          label={t('name')}
          value={props.name}
          onChange={props.setName}
        />
        <Select
          label={t('primary_category')}
          options={getCategoryOptions()}
          onChange={props.setCategoryId}
          value={props.categoryId}
        />
        <CouponMultiSelect
          label={t('additional_categories')}
          options={getCategoryOptions()}
          onChange={props.setAltCategoryIds}
          value={props.altCategoryIds}
        />
        {renderContentInput()}
        <Input
          label={t('address')}
          value={props.address}
          onChange={props.setAddress}
        />
        <div className={classes.coordsRow}>
          <div className={classes.column}>
            <Input
              label={t('latitude')}
              value={props.latitude}
              onChange={handleSetLatitude}
              className={classes.coordInput}
            />
          </div>
          <div className={classes.spacer} />
          <div className={classes.column}>
            <Input
              label={t('longitude')}
              value={props.longitude}
              onChange={handleSetLongitude}
              className={classes.coordInput}
            />
          </div>
          <Button
            buttonStyle={classes.coordsButton}
            text={t('get_coordinates')}
            onClick={getCoordinates}
            small
            secondary
          />
        </div>
        <div className={classes.mapContainer}>
          {renderMap()}
        </div>
        <Title
          title={t('contact_information')}
          type='subtitle'
        />
        <div className={classes.contactRow}>
          <div className={classes.phoneInputContainer}>
            <Input
              label={t('phone')}
              value={props.phone}
              onChange={props.setPhone}
            />
          </div>
          <div className={classes.spacer} />
          <div className={classes.emailInputContainer}>
            <Input
              label={t('email')}
              value={props.email}
              onChange={props.setEmail}
            />
          </div>
        </div>
        <Input
          label={t('url')}
          value={props.url}
          onChange={props.setUrl}
        />
      </div>
    </>
  )
}

export default CouponOrganizationForm
