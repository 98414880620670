import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1.5rem'
  },
  innerContainer: {
    width: '100%',
    position: 'relative'
  },
  textFieldRoot: {
    width: '100%'
  },
  inputRoot: {
    width: '100%',
    backgroundColor: Colors.inputGrey,
    ':active': {
      backgroundColor: Colors.white
    }
  },
  inputWhite: {
    background: Colors.white,
    ':active': {
      backgroundColor: Colors.white
    }
  },
  inputMultilineRoot: {
    width: '100%',
    padding: 0,
    backgroundColor: Colors.inputGrey,
    ':active': {
      backgroundColor: Colors.white
    }
  },
  input: {
    width: '100%',
    height: '3.125rem',
    fontFamily: Fonts.content,
    fontWeight: 300,
    fontSize: '1.125rem',
    paddingBottom: 2,
    color: Colors.black,
    backgroundColor: Colors.inputGrey,
    borderRadius: 5,
    padding: '0 1rem',
    '&:focus': {
      backgroundColor: Colors.white
    },
    '&::placeholder': {
      opacity: 1
    }
  },
  inputWhiteBackground: {
    backgroundColor: Colors.white,
    '&:focus': {
      backgroundColor: Colors.white
    }
  },
  multiline: {
    minHeight: '12rem',
    maxHeight: '12rem',
    fontFamily: Fonts.content,
    fontWeight: 300,
    fontSize: '1.125rem',
    lineHeight: '1.4rem',
    color: Colors.black,
    backgroundColor: Colors.inputGrey,
    borderRadius: 5,
    padding: '1.125rem 1rem',
    '&:focus': {
      backgroundColor: Colors.white
    }
  },
  inputLabel: {
    fontFamily: Fonts.content,
    color: Colors.content,
    fontSize: '1.125rem',
    marginBottom: '.25rem'
  },
  inputLabelDisabled: {
    opacity: 0.5
  },
  leftIconContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '1rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  leftIcon: {
    width: '100%',
    maxWidth: '1.5rem',
    zIndex: 1
  },
  inputExtraPaddingLeft: {
    paddingLeft: '3rem'
  },
  disabledInput: {
    backgroundColor: Colors.inputGrey,
    opacity: 0.5
  }
}))

export default function Input(props) {
  const classes = useStyles()

  const onChange = (evt) => props.onChange(evt.target.value)

  const renderLeftIcon = () => {
    if (props.leftIcon) {
      return (
        <div className={classes.leftIconContainer}>
          <img src={props.leftIcon} className={classes.leftIcon} alt='left-icon' />
        </div>
      )
    }
    return null
  }

  const getInputStyle = () => {
    const inputStyles = []

    if (props.multiline) {
      inputStyles.push(classes.multiline)
    } else {
      inputStyles.push(classes.input)
    }
    if (props.leftIcon) {
      inputStyles.push(classes.inputExtraPaddingLeft)
    }
    if (props.whiteBackground) {
      inputStyles.push(classes.inputWhiteBackground)
    }

    if (props.disabled) {
      inputStyles.push(classes.disabledInput)
    }
    if (props.white) {
      inputStyles.push(classes.inputWhite)
    }

    return inputStyles.join(' ')
  }

  const getInputRootStyle = () => {
    const activeClasses = [props.multiline ? classes.inputMultilineRoot : classes.inputRoot]
    if (props.white) activeClasses.push(classes.inputWhite)
    if (props.disabled) {
      activeClasses.push(classes.disabledInput)
    }
    return activeClasses.join(' ')
  }

  return (
    <div className={classes.container}>
      {!!props.label && <div className={[classes.inputLabel, props.disabled ? classes.inputLabelDisabled : ''].join(' ')}>{props.label}</div>}
      <div className={classes.innerContainer}>
        {renderLeftIcon()}
        <TextField
          id={props.id}
          value={props.value || ''}
          onChange={onChange}
          onKeyPress={props.handleKeyPress}
          placeholder={props.placeholder}
          classes={{ root: classes.textFieldRoot }}
          InputProps={{
            classes: {
              root: getInputRootStyle(),
              input: getInputStyle()
            }
          }}
          inputProps={{ maxLength: props.maxLength }}
          type={props.type}
          multiline={props.multiline}
          variant='outlined'
          autoFocus={!!props.autoFocus}
          disabled={props.disabled}
          error={props.error}
          helperText={props.helperText}
        />
      </div>
    </div>
  )
}
