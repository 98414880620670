import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'
import DeleteIcon from '../../Assets/Icons/delete-navy.svg'

const useStyles = makeStyles((theme) =>({
  container: {
    paddingTop: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {
    position: 'relative',
    backgroundColor: Colors.tableRowBackgroundColor,
    borderRadius: 5,
    padding: '1.5rem',
    boxSizing: 'border-box',
    height: '15.375rem',
    width: '22.5rem',
    marginBottom: '1rem',
    marginRight: '2rem',
    textDecoration: 'none',
    color: Colors.heading,
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: Colors.tableRowBackgroundHover
    }
  },
  cardDeleted: {
    paddingTop: '2rem'
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  published: {
    fontFamily: Fonts.content,
    fontSize: '0.875rem',
    fontWeight: 400,
    color: Colors.black70,
    margin: 0
  },
  validity: {
    fontFamily: Fonts.content,
    fontSize: '0.875rem',
    fontWeight: 100,
    color: Colors.black70,
    margin: 0
  },
  title: {
    fontFamily: Fonts.heading,
    fontSize: '1.5rem',
    fontWeight: 400,
    margin: '0.125rem 0 0.5rem',
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  content: {
    width: '19.5rem',
    fontFamily: Fonts.content,
    fontSize: '1rem',
    fontWeight: 300,
    color: Colors.black70,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  badgeRow: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'row'
  },
  badge: {
    backgroundColor: Colors.cardBackgroundColor,
    fontSize: '0.75rem',
    color: Colors.brandColor2,
    borderRadius: 5,
    padding: '0.7125rem',
    marginRight: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  deleteBadge: {
    backgroundColor: Colors.white,
    fontSize: '0.75rem',
    color: Colors.brandColor2,
    borderRadius: 5,
    padding: '0.625rem 0.525rem',
    marginRight: '0.5rem'
  },
  deletedBadge: {
    position: 'absolute',
    top: 0,
    right: '.5rem',
    color: Colors.white,
    background: Colors.red,
    display: 'inline-block',
    padding: '.25rem .66rem'
  },
  flex: {
    flex: 1
  },
  deleteButton: {
    paddingLeft: '.825rem',
    paddingRight: '.825rem',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    }
  },
  deleteIcon: {
    height: '1.25rem'
  }
}))

function PollGrid (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getPublishedAt = (item) => {
    if (item?.validFrom) {
      const publishedAt = moment(item.validFrom).format('DD.MM.YYYY')
      return `${t('published_at')} ${publishedAt}`
    }
    return null
  }

  const getValidity = (item) => {
    const isOpen = item?.isOpen ?? false
    const startDate = item?.validFrom ? moment(item?.validFrom) : null
    const endDate = item?.validUntil ? moment(item?.validUntil) : null
    if (startDate && endDate) {
      if (isOpen) {
        const daysUntilEnd = endDate.diff(moment(), 'days')
        if (daysUntilEnd > 30) {
          return `- ${t('closes_in')} ${endDate.format('DD.MM.YYYY')}`
        } else {
          return `- ${t('closes_in')} ${daysUntilEnd} ${daysUntilEnd > 9 ? t('after_days_short') : t('after_days')}`
        }
      } else {
        return `- ${t('closed_at')} ${endDate.format('DD.MM.YYYY')}`
      }
    }
    return null
  }

  const renderItems = () => {
    if (props.items) {
      return props.items.map((item) => (
        <Link
          key={item.id}
          to={`/polls/${item.id}`}
          className={[classes.card, (!item.active ? classes.cardDeleted : '')].join(' ')}
        >
          {!item.active && <div className={classes.deletedBadge}>{t('deleted')}</div>}
          <div className={classes.headerRow}>
            <p className={classes.published}>{getPublishedAt(item)}</p>
            <span>&nbsp;</span>
            <p className={classes.validity}>{getValidity(item)}</p>
          </div>
          <h3 className={classes.title}>{item.titleFi}</h3>
          <p className={classes.content}>{item.contentFi} </p>
          <div className={classes.badgeRow}>
            <div className={classes.badge}>
              {t('question_count')} {item.questions ? item.questions.length : 0}
            </div>
            <div className={classes.badge}>
              {t('answerer_count')} {item.totalAnswers || 0}
            </div>
            <div className={classes.flex} />
            {!!item.active && (
              <div onClick={(e) => { e.preventDefault(); props.onDelete(item.id) }} className={[classes.deleteBadge, classes.deleteButton].join(' ')}>
                <img src={DeleteIcon} className={classes.deleteIcon} alt='delete' />
              </div>
            )}
          </div>
        </Link>
      ))
    }
    return null
  }

  return (
    <div className={classes.container}>
       {renderItems()}
    </div>
  )
}

export default PollGrid
