import moment from 'moment'

export const validatePoll = (poll) => {
  // Don't validate drafts
  if (poll.draft) return null

  // No questions set -> activate / deactivate action
  if (!poll.questions) return null

  const fieldCounters = {
    Fi: 0,
    En: 0
  }

  for (const locale of ['Fi', 'En']) {
    for (const field of ['title', 'content']) {
      if (poll[`${field}${locale}`]) {
        fieldCounters[locale] = fieldCounters[locale] + 1
      }
    }

    if (!poll.questions.length) {
      return 'validation_missing_question'
    }

    // Check that questions are either filled or empty
    for (const question of poll.questions) {
      for (const field of ['title']) {
        if (question[`${field}${locale}`]) {
          fieldCounters[locale] = fieldCounters[locale] + 1
        }
      }
      if (question.type !== 'text') {
        if (question[`answers${locale}`]) {
          fieldCounters[locale] = fieldCounters[locale] + (question[`answers${locale}`].filter(val => !!val)).length
        }
      }
    }
  }

  if (!fieldCounters.Fi && !fieldCounters.En) {
    return 'validation_fill_all_fields'
  }

  // Validate dates
  if (!poll.validFrom) return 'validation_missing_valid_from'

  return null
}

export const validateCoupon = (coupon) => {
  // Validate required fields
  const fields = [
    'organizationId',
    'validFrom',
    'validUntil'
  ]
  for (const field of fields) {
    if (!coupon[field]) return 'validation_fill_all_fields'
  }

  // Validate dates
  if (!coupon.validFrom) return 'validation_missing_valid_from'
  if (!coupon.validUntil) return 'validation_missing_valid_until'

  if (moment(coupon.validUntil).isBefore(moment(coupon.validFrom))) {
    return 'validation_valid_until_before_valid_from'
  }

  // Validate localized fields
  const localizedFields = ['title', 'content']
  const localeCounters = {
    Fi: 0,
    En: 0
  }
  for (const locale of ['Fi', 'En']) {
    for (const localizedField of localizedFields) {
      if (coupon[`${localizedField}${locale}`]) {
        localeCounters[locale] = localeCounters[locale] + 1
      }
    }
  }
  if (localeCounters.Fi === 0 && localeCounters.En === localizedFields.length) return null
  if (localeCounters.En === 0 && localeCounters.Fi === localizedFields.length) return null
  if (localeCounters.Fi === localizedFields.length && localeCounters.En === localizedFields.length) return null
  return 'validation_fill_all_fields'
}
