import React from 'react'
import moment from 'moment'
import { capitalize } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: `${Colors.tableRowBackgroundHover} !important`
    }
  },
  deleted: {
    border: `1px solid ${Colors.red}`,
    opacity: 0.5
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 300,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none'
  },
  firstCell: {
    fontWeight: 600,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  link: {
    textDecoration: 'none',
    color: Colors.black
  },
  photo: {
    height: '2.5rem',
    width: '2.5rem',
    objectFit: 'cover',
    borderRadius: '100%',
    marginRight: '1rem'
  },
  buttonLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.white,
    width: 32,
    height: 32,
    borderRadius: 5,
    marginRight: '1rem'
  }
}))

function UserTableRow (props) {
  const classes = useStyles()
  const { user } = props
  const { t } = useTranslation()

  const handleClick = () => props.history.push(`/users/${user.id}`)

  const statusClass = user.active ? '' : classes.deleted

  const getName = () => {
    const { firstName, lastName, email } = user
    if (firstName || lastName) {
      return `${firstName} ${lastName}`.trim()
    }
    if (email) {
      // Compose name from email
      try {
        return email.split('@')[0].split('.').map(p => capitalize(p)).join(' ')
      } catch (err) {
        console.log(err)
      }
    }
    return null
  }

  const renderRole = () => {
    if (
      user.role === 'user' &&
      user.accessRights &&
      user.accessRights.includes('coupon_organization_user')
    ) {
      return t('coupon_organization_user')
    }
    return t(user.role)
  }

  return (
    <TableRow classes={{ root: [classes.root, statusClass].join(' ') }} hover>
      <TableCell onClick={handleClick} className={[classes.cell, classes.firstCell].join(' ')}>
        <div className={classes.row}><span>{getName()}</span></div>
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>{user.email}</TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>{renderRole()}</TableCell>
      <TableCell onClick={handleClick} className={[classes.cell, classes.lastCell].join(' ')}>
        {user?.createdAt ? moment(user.createdAt).format('DD.MM.YYYY') : null}
      </TableCell>
    </TableRow>
  )
}

export default withRouter(UserTableRow)
