import i18n from '../Localization'
import users from '../Assets/Icons/user.svg'
import analytics from '../Assets/Icons/analytics.svg'
import settings from '../Assets/Icons/settings.svg'
import notifications from '../Assets/Icons/notifications.svg'
//import coupon from '../Assets/Icons/coupon.svg'
//import feedback from '../Assets/Icons/feedback.svg'
//import poll from '../Assets/Icons/poll.svg'
//import CampaignIcon from '../Assets/Icons/campaign.svg'
import CompanyIcon from '../Assets/Icons/company.svg'
import CategoryIcon from '../Assets/Icons/category.svg'
import OfferIcon from '../Assets/Icons/offer.svg'
import AddCouponIcon from '../Assets/Icons/add-coupon.svg'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyBCz84tW3RtJ0L3K9P1Sh1HYtwUnq7j57w'

export const CITY_LOCATION = {
  latitude: 60.19982,
  longitude: 24.93372,
  radius: 30000
}

export enum Role {
  Admin = 'admin',
  User = 'user'
}
export const ROLES = ['admin', 'user']

export const ACCESS_RIGHTS = [
  'coupon',
  'notification',
  //'poll',
  //'influence',
  'analytics'
]

export const AvailableRoutes = (user, sidebarCouponMode) => {
  if (!user || !user.role) return []
  const { role, accessRights } = user

  const accessCheck = (accessRight, data) => {
    if (role === 'admin') return data
    if (accessRights && accessRights.includes(accessRight)) {
      return data
    }
    // Handle coupon organization users
    if (accessRight === 'coupon' && accessRights && accessRights.includes('coupon_organization_user')) {
      return data
    }
    return null
  }

  const isCouponOrganizationUser = accessRights && accessRights.includes('coupon_organization_user')


  // If coupon user
  return [
    { url: '/coupons/add', icon: AddCouponIcon, text: 'add_coupon' },
    { url: '/coupons', icon: OfferIcon, text: 'coupons' },
    { url: '/coupon-organizations', icon: CompanyIcon, text: 'organizations' },
    isCouponOrganizationUser ? null : { url: '/coupon-categories', icon: CategoryIcon, text: 'categories' },
    accessCheck('analytics', { url: '/analytics', icon: analytics, text: 'analytics' }),
    accessCheck('notification', { url: '/coupon-notifications', icon: notifications, text: 'notifications' }),
    accessCheck('admin', { url: '/users', icon: users, text: 'users' }),
    { url: '/settings', icon: settings, text: 'settings' }
  ].filter(item => !!item)
}

export const UserSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export const CouponSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' },
  { id: 3, label: i18n.t('sort_by_popularity'), value: 'popularity' }
]

export const OrganizationSortOptions = [
  { id: 1, label: i18n.t('sort_by_alphabetical_order'), value: 'alphabetical' },
  { id: 2, label: i18n.t('sort_by_created_at'), value: 'created_at' }
]

export const APP_RELEASE_DATES = [
  /*
  {
     date: '2023-01-01',
     name: '1.0.0'
  }
  */
]

/**
 * This is for Screen level analytics. Any new screens added to App should be mapped here.
 * If you add new analyticsGroups, remember to also add fi translation  "analytics_group_*"  for it
 */
export const APP_SCREEN_MAPPINGS = [
   {
     group: 'influence',
     analyticsGroups: ['poll'],
     groupName: 'Osallistu ja vaikuta',
     screens: [
        {
          screen: 'PollsScreen',
          screenName: 'Kyselyt',
        },
        {
          screen: 'PollResultsScreen',
          screenName: 'Kyselyn tulokset'
        },
        {
          screen: 'FeedbackScreen',
          screenName: 'Palaute'
        },
        {
          screen: 'FaultReportScreen',
          screenName: 'Vikailmoitukset'
        },
        {
          screen: 'InfluenceInfoScreen',
          screenName: 'Ohjeet'
        }
      ]
   },
   {
     group: 'pass',
     analyticsGroups: ['coupon'],
     groupName: 'Tarjouksets',
     screens: [
        {
          screen: 'NearbyCouponsScreen',
          screenName: 'Lähelläsi'
        },
        {
          screen: 'CouponsScreen',
          screenName: 'Tarjoukset'
        },
        {
          screen: 'CouponScreen',
          screenName: 'Tarjous'
        },
        {
          screen: 'CouponFavoritesScreen',
          screenName: 'Suosikit'
        },
        {
          screen: 'SingleCouponScreen',
          screenName: 'Tarjous'
        },
        {
          screen: 'CouponsMapScreen',
          screenName: 'Näytä kartalla'
        },
        {
          screen: 'CouponsInfoScreen',
          screenName: 'Mikä passi?'
        },
        {
          screen: 'ScanCouponCampaignCodeScreen',
          screenName: 'Kampajakoodin skannaus'
        },
        {
          screen: 'RedeemCouponCampaignPrizeScreen',
          screenName: 'Lunasta palkinto'
        }
      ]
   },
   {
     group: 'notifications',
     analyticsGroups: [
       'city_announcement',
       'notification'
     ],
     groupName: 'Notifikaatiot',
     screens: [
        {
          screen: 'NotificationsScreen',
          screenName: 'Notifikaatiot'
        },
        {
          screen: 'MessageLogScreen',
          screenName: 'Notifikaatiot'
        },
        {
          screen: 'NotificationScreen',
          screenName: 'Notifikaatio'
        },
        {
          screen: 'SingleNotificationScreen',
          screenName: 'Notifikaatio'
        },
        {
          screen: 'MessageScreen',
          screenName: 'Notifikaatio'
        },
        {
          screen: 'CityNotificationsScreen',
          screenName: 'Kaupungin tiedotteet'
        },
        {
          screen: 'CityNotificationScreen',
          screenName: 'Kaupungin tiedote'
        }
      ]
   },
   {
     group: 'settings',
     groupName: 'Asetukset',
     screens: [
        {
          screen: 'SettingsScreen',
          screenName: 'Asetukset'
        },
        {
          screen: 'InfoScreen',
          screenName: 'Tietoa sovelluksesta'
        },
        {
          screen: 'LanguageSelectionScreen',
          screenName: 'Kielivalinta'
        },
        {
          screen: 'CreateAccountScreen',
          screenName: 'Luo käyttäjätunnus'
        },
        {
          screen: 'LocationSettingsScreen',
          screenName: 'Sijaintiasetukset'
        },
        {
          screen: 'TermsScreen',
          screenName: 'Käyttöehdot'
        },
        {
          screen: 'EditDashboardScreen',
          screenName: 'Muokkaa pikavalintoja'
        },
        {
          screen: 'CreateAccountConfirmationScreen',
          screenName: 'Käyttäjätunnuksen vahvistus'
        },
        {
          screen: 'VersionHistoryScreen',
          screenName: 'Versiohistoria'
        },
        {
          screen: 'BugReportScreen',
          screenName: 'Ilmoita bugista'
        },
        {
          screen: 'NotificationSettingsScreen',
          screenName: 'Notifikaatio asetukset'
        },
        {
          screen: 'MenuOrderSettingsScreen',
          screenName: 'Valikon järjestyksen muokkaus'
        },
        {
          screen: 'AppFeedbackScreen',
          screenName: 'Sovelluspalaute'
        }
      ]
   },
   {
     group: 'weather',
     groupName: 'Sää',
     screens: [
        {
          screen: 'WeatherScreen',
          screenName: 'Sää'
        }
      ]
   }
]

