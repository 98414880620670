import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import PageHeader from '../../../Components/Common/PageHeader'
import { useStore } from '../../../Models/RootStore'
import { Title, Input, Select, Button, PageActions, PageContent, PageContainer, AccessRightsSelector } from '../../../Components'
import { ROLES } from '../../../Constants'
import { Colors, Fonts } from '../../../Utils/theme'
import CouponMultiSelect from '../../../Components/Coupons/CouponMultiselect'
import { sortBy } from 'lodash'

const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 400,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  organizationSelectContainer: {
    marginTop: '-.5rem'
  }
}))

function InviteUser(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [accessRights, setAccessRights] = useState([])
  const [couponOrganizationIds, setCouponOrganizationIds] = useState([])

  const getRoles = () => {
    return [...ROLES, 'coupon_organization_user'].map((role, index) => ({
      id: index + 1,
      label: t(role),
      value: role
    }))
  }

  const { userStore, appStore, couponStore } = useStore()
  const inviteUser = () => {
    setSubmitted(true)
    if (role === 'coupon_organization_user') {
      userStore.inviteUser({
        email,
        role: 'user',
        accessRights: ['coupon_organization_user'],
        couponOrganizationIds
      })
    } else {
      userStore.inviteUser({
        email,
        role,
        accessRights: role === 'admin' ? [] : accessRights,
      })
    }
  }

  useEffect(() => {
    couponStore.getOrganizations()
  }, [])

  useEffect(() => {
    if (appStore.notification && appStore.notification.type === 'success') {
      // Clear fields after success
      setEmail('')
      setRole('')
      setSubmitted(false)
      setAccessRights([])
      setCouponOrganizationIds([])
    }
  }, [appStore.notificationUpdatedAt])

  const emailValidation = () => {
    if (submitted && !/\S+@\S+\.\S+/.test(email)) {
      return {
        error: true,
        helperText: t('invalid_email')
      }
    }
    return {}
  }

  const roleValidation = () => {
    if (submitted && !role) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
  }

  const getOrganizationOptions = () => {
    return sortBy(couponStore.organizations.map(org => {
      return {
        value: org.id,
        label: org.name
      }
    }), 'label')
  }

  const isInviteDisabled = () => {
    if (!email || email.length < 5 || !email.includes('@')) return true
    if (role === 'coupon_organization_user' && !couponOrganizationIds.length) return true
    return false
  }

  const renderCouponOrganizationSelect = () => {
    if (role !== 'coupon_organization_user') return null
    return (
      <div className={classes.organizationSelectContainer}>
        <Title
          description={t('coupon_organization_user_select_organization')}
          type='subtitle'
        />
        <CouponMultiSelect
          label={t('organizations')}
          options={getOrganizationOptions()}
          onChange={setCouponOrganizationIds}
          value={couponOrganizationIds}
        />
      </div>
    )
  }

  return (
    <>
      <PageHeader
        title={t('add_new_user')}
        backButtonLink='/users'
      />
      <PageContainer>
        <PageContent>
          <Title title={t('user_details')} description={t('create_user_description')} />
          <Input label={t('email')} value={email} onChange={setEmail} {...emailValidation()} />
          <Title title={t('account_details')} description={t('create_user_account_description')} type='subtitle' spacer={10} />
          <Select options={getRoles()} label={t('role')} value={role} onChange={setRole} {...roleValidation()} />
          {(!role || role === 'admin' || role === 'coupon_organization_user') ? null : (
            <AccessRightsSelector
              accessRights={accessRights}
              setAccessRights={setAccessRights}
            />
          )}
          {renderCouponOrganizationSelect()}
        </PageContent>
        <PageActions>
          <p className={classes.text}>{t('create_user_action_description')}</p>
          <Button
            text={t('send_invite')}
            onClick={inviteUser}
            margin
            disabled={isInviteDisabled()}
          />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(InviteUser)
