import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Linkify from 'react-linkify'
import get from 'lodash/get'
import { Colors, Fonts } from '../../Utils/theme'
import PollAnswerChart from './PollAnswersChart'
import PollResultTextAnswers from './PollResultTextAnswers'

const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: '1.125rem'
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerSpacer: {
    width: '2.5rem'
  },
  smallBox: {
    border: `1px solid ${Colors.black50}`,
    padding: '1rem 0 1.125rem',
    textAlign: 'center',
    minWidth: '12.5rem',
    borderRadius: '.3125rem'
  },
  value: {
    color: Colors.black,
    fontFamily: Fonts.heading,
    fontSize: '3rem',
    fontWeight: 500
  },
  subtitle: {
    fontFamily: 'Roboto',
    color: Colors.black,
    fontWeight: 300,
    fontSize: '1.125rem'
  },
  questionContainer: {
    borderRadius: '.3125rem',
    border: `1px solid ${Colors.black50}`,
    padding: '1.75rem',
    marginTop: '2.5rem'
  },
  questionNum: {
    fontWeight: 300,
    fontSize: '1.125rem'
  },
  questionTitle: {
    marginTop: '.25rem',
    fontWeight: 700,
    fontSize: '1.25rem',
    color: Colors.brandColor2
  },
  questionContent: {
    marginTop: '.25rem',
    marginBottom: '.25rem',
    fontWeight: 400,
    color: Colors.black50
  },
  questionInfo: {
    padding: '.25rem 0',
    color: Colors.black50
  },
  textAnswerContainer: {
    borderRadius: '.3125rem',
    marginTop: '1rem',
    backgroundColor: Colors.lightGrey,
    padding: '1.25rem 1.75rem'
  },
  ansDate: {
    color: Colors.black70,
    fontWeight: 300
  },
  newLine: {
    marginBottom: '1rem'
  },
  optionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  optionActive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    color: Colors.white,
    backgroundColor: Colors.black,
    borderRadius: '2rem',
    minHeight: '2rem',
    minWidth: '2rem'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    color: Colors.brandColor2,
    backgroundColor: Colors.white,
    borderRadius: '2rem',
    minHeight: '2rem',
    minWidth: '2rem'
  },
  optionText: {
    marginLeft: '1rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    borderLeft: `1px solid ${Colors.black20}`
  },
  optionRowsContainer: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  optionRowLeft: {
    flex: 1
  },
  optionDivider: {

  },
  optionRowRight: {
    flex: 1,
    marginTop: '-2.125rem',
    paddingLeft: '1.5rem'
  },
  questionHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  questionHeaderLeft: {
    flex: 1
  },
  questionHeaderRight: {
    paddingLeft: '2rem',
    flex: 1
  }
}))

function PollResult(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  const renderWithNewLines = (text) => {
    const componentDecorator = (href, text, key) => <a key={key} href={href} target='_blank' rel='noreferrer noopener'>{text}</a>
    return !text ? text : text.split('\n').map((line, index) => (
      <div key={index} className={classes.newLine}>
        <Linkify componentDecorator={componentDecorator}>{line}</Linkify>
      </div>
    ))
  }

  const getConversion = () => {
    const viewCount = getViewCount()
    const ansCount = get(props.poll, 'totalAnswers', 0)

    if (viewCount && (ansCount <= viewCount)) {
      return `${Math.round((ansCount / viewCount) * 100)}%`
    }

    return '-'
  }
  const getActivations = () => '-'
  const getViewCount = () => {
    return props.pollViews || 0
  }

  const charIndex = (number) => number < 26 ? String.fromCharCode(number + 65) : number

  const renderHeader = () => {
    return (
      <div className={classes.headerRow}>
        <div className={classes.smallBox}>
          <div className={classes.value}>{get(props.poll, 'totalAnswers', 0)}</div>
          <div className={classes.subtitle}>{t('num_answred_users')}</div>
        </div>
        <div className={classes.headerSpacer} />
        <div className={classes.smallBox}>
          <div className={classes.value}>{getViewCount()}</div>
          <div className={classes.subtitle}>{t('num_views')}</div>
        </div>
        <div className={classes.headerSpacer} />
        <div className={classes.smallBox}>
          <div className={classes.value}>{getConversion()}</div>
          <div className={classes.subtitle}>{t('conversion')}</div>
        </div>
        <div className={classes.headerSpacer} />
        <div className={classes.smallBox} style={{ opacity: 0 }}>
          <div className={classes.value}>{getActivations()}</div>
          <div className={classes.subtitle}>{t('num_activations')}</div>
        </div>
      </div>
    )
  }

  const getAnswerCount = (index) => {
    const question = props.poll.questions[index]
    const answers = getPollAnswers().filter(item => item.questionId === question.id).filter(item => {
      return item.textAnswer !== '-'
    })
    return answers.length
  }

  const renderTextAnswers = (index) => {
    const question = props.poll.questions[index]
    const answers = getPollAnswers().filter(item => item.questionId === question.id).filter(item => {
      return item.textAnswer && item.textAnswer !== '-'
    })

    if (!answers.length) return null

    return (
      <PollResultTextAnswers
        answers={answers}
      />
    )
  }

  const getPollAnswers = () => {
    if (props.pollFull) {
      return (props.pollFull.answers || [])
    }
    return []
  }


  const renderSelectAnswers = (index) => {
    const question = props.poll.questions[index]

    const answers = getPollAnswers().filter(item => item.questionId === question.id)
    let answersFi = question.answersFi
    let answersEn = question.answersEn

    if (question.otherEnabled) {
      answersFi = [...answersFi, 'Jokin muu, mikä?']
      answersEn = [...answersEn, 'Something else, what?']
    }

    return (
      <div>
        <div className={classes.optionRowsContainer}>
          <div className={classes.optionRowLeft}>
            {(answersFi || []).map((_, index) => {
              const optionText = answersFi[index] || answersEn[index]
              const isActive = false
              return (
                <div className={classes.optionRow}>
                  <div className={isActive ? classes.optionActive : classes.option}>
                    {charIndex(index)}
                  </div>
                  <div className={classes.optionText}>
                    {optionText}
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classes.optionDivider} />
          <div className={classes.optionRowRight}>
            <PollAnswerChart
              question={question}
              answers={answers}
            />
          </div>
        </div>
        {renderTextAnswers(index)}
      </div>
    )
  }

  const renderVideo = () => {
    const video = get(props.poll, 'imageUrlFi')
    if (video) {
      const isImage = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'].find(ext => video.toLowerCase().includes(ext))
      if (isImage) {
        return <img src={video} className={classes.image} alt='video' />
      }

      // Youtube or vimeo
      return <iframe title='video' src={video} height='300' width='534' style={{ marginTop: 20, border: 0 }} />
    }
  }

  const renderAnswers = (questionIndex) => {
    const questions = get(props.poll, 'questions', [])
    const question = questions[questionIndex]

    if (question.type === 'text') {
      return renderTextAnswers(questionIndex)
    }

    if (question.type === 'select' || question.type === 'multiselect') {
      return renderSelectAnswers(questionIndex)
    }
    return null
  }

  const renderQuestions = () => {
    const questions = get(props.poll, 'questions', [])
    return questions.map((question, index) => {
      return (
        <div className={classes.questionContainer}>
          <div className={classes.questionHeader}>
            <div className={classes.questionHeaderLeft}>
              <div className={classes.questionNum}>{t('question')} {index + 1}.</div>
              <div className={classes.questionTitle}>{get(question, 'titleFi') || get(question, 'titleEn')}</div>
              <div className={classes.questionContent}>{renderWithNewLines(get(question, 'contentFi') || get(question, 'contentEn'))}</div>
            </div>
            <div className={classes.questionHeaderRight}>
              <div className={classes.questionNum}>{'\u00a0'}</div>
              <div className={classes.questionTitle}>{t('answers_num')} {getAnswerCount(index)} {t('psc')}</div>
              <div className={classes.questionInfo}>{t('question_type')}: {t(`answer_type_${get(question, 'type')}`)}</div>
              <div className={classes.questionInfo}>{!get(props.poll, 'isOptional') ? t('mandatory_question') : t('is_optional_question')}</div>
            </div>
          </div>
          {renderAnswers(index)}
        </div>
      )
    })
  }

  if (!props.pollFull) return null

  return (
    <div className={classes.container}>
      {renderHeader()}
      {renderQuestions()}
    </div>
  )
}

export default PollResult
