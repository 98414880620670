import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import * as moment from 'moment'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter } from 'react-router-dom'
import { PageHeader, CouponTable, DataActions, PageContainer, PageContent } from '../../../Components'
import { CouponSortOptions, UserSortOptions } from '../../../Constants'
import { makeStyles } from '@material-ui/core'
import { Colors } from '../../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  innerLeft: {
    flex: 5
  },
  innerRight: {
    flex: 3
  },
  innerRightOffset: {
    position: 'relative',
    paddingLeft: '6rem',
    marginTop: '-5rem'
  },
  hr: {
    position: 'absolute',
    left: '3rem',
    top: '-1.75rem',
    width: 1,
    minHeight: 'calc(100vh - 4rem)',
    backgroundColor: Colors.lightBorder
  }
}))

function Coupons(props) {
  const { t } = useTranslation()
  const { couponStore } = useStore()
  const classes = useStyles()

  useEffect(() => {
    couponStore.getCoupons()
    couponStore.getOrganizations()
    couponStore.getCategories()
    couponStore.getNotifications()
  }, [])


  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)
  const [tab, setTab] = useState(0)

  const selectTab = (evt, value) =>  setTab(value)

  const tabs = [
    { id: 0, label: t('active_ones') },
    { id: 1, label: t('ended_ones') },
    { id: 2, label: t('ending_soon_ones') },
    { id: 3, label: t('deleted_ones') }
  ]

  const toCreate = () => {
    props.history.push('/coupons/add')
  }

  /* Coupons */
  const getCoupons = () => {
    let coupons = couponStore.coupons

    if (searchValue) {
      coupons = coupons.filter((coupon) => {
        const { titleFi = '', titleEn = '' } = coupon
        const flatSearchCategories = (get(coupon, 'categoryNames') || []).join(' ')
        const organizationName = get(coupon, 'organization.name', '')
        const targets = [
          titleFi,
          titleEn,
          organizationName,
          flatSearchCategories
        ]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }
    if (sortValue === 'created_at') {
      coupons = orderBy(coupons, 'createdAt', 'desc')
    } else if (sortValue === 'popularity') {
      coupons = orderBy(coupons, 'totalUseCount', 'desc')
    } else {
      coupons = orderBy(coupons, 'organization.name', 'asc')
    }

    // Filter
    coupons = coupons.filter(item => {
      if (tab === 0) {
        if (!item.active) return false
        if (item.validUntil && moment().isAfter(moment(item.validUntil))) return false
        return true
      }
      else if (tab === 1) {
        if (!item.active) return false
        if (!item.validUntil) return false
        if (item.validUntil && !moment().isAfter(moment(item.validUntil))) return false
        return true
      }
      else if (tab === 2) {
        if (!item.active) return false
        if (!item.validUntil) return false

        const min = moment().unix()
        const itemTime = moment(item.validUntil).unix()
        const max = moment().add(2, 'weeks').unix()
        if (min < itemTime && itemTime < max) {
          return true
        }
        return false
      }
      else if (tab === 3) return !item.active
      return true
    })

    // Sort deleted last
    coupons = orderBy(coupons, 'active', 'desc')

    return coupons
  }



  const renderDataActions = () => {
    return (
      <div className={classes.innerContainer}>
        <div className={classes.innerLeft}>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={ CouponSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <PageHeader
        title={t('coupons')}
        tabs={tabs}
        currentTab={tab}
        onTabChange={selectTab}
        onCreateClick={toCreate}
        createText={t('create_coupon')}
      />
      <PageContainer>
        <PageContent>
          {renderDataActions()}
          <CouponTable items={getCoupons()} />
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(Coupons))
