import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Checkbox, Select } from '..'
import { Button, Title, Input } from '../'
import { v4 as uuidv4 } from 'uuid'
import { Colors } from '../../Utils/theme'
import MediaInput from '../../Containers/Authorized/Notifications/MediaInput'
import PollQuestionForm from './PollQuestionForm'
import PollLangButton from './PollLangButton'
import AddIcon from '../../Assets/Icons/add-red.svg'

const useStyles = makeStyles((theme) =>({
  container: {
  },
  introContainer: {
    backgroundColor: Colors.white,
    padding: '1.5rem',
    border: `1px solid ${Colors.black50}`,
    position: 'relative',
    marginBottom: '2rem',
    borderRadius: '0.1875rem'
  },
  mediaContainer: {
    backgroundColor: Colors.white,
    padding: '.5rem 1.5rem 1.5rem',
    border: `1px solid ${Colors.black50}`,
    position: 'relative',
    marginBottom: '2rem',
    borderRadius: '0.1875rem'
  },
  introHeader: {
    flexDirection: 'row',
    paddingBottom: '0.75rem'
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 400,
    color: Colors.heading,
    margin: 0,
    padding: '0 0 0.75rem',
    flex: 1
  },
  actionButtons: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem'
  },
  tabActive: {
    cursor: 'pointer',
    color: Colors.black,
    fontSize: '2.25rem',
    fontWeight: 500
  },
  tab: {
    cursor: 'pointer',
    opacity: 0.3,
    color: Colors.black,
    fontSize: '2.25rem',
    fontWeight: 500,
    '&:hover': {
      opacity: 0.5
    }
  },
  tabSpacer: {
    width: '3.75rem'
  },
  settingsDescription: {
    fontWeight: 300,
    fontSize: '1.125rem',
    paddingBottom: '.85rem'
  },
  settingsRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  checkboxContainer: {
    paddingRight: '1rem'
  },
  categoryContainer: {
    maxWidth: '25rem'
  }
}))

function PollForm (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [tab, setTab] = useState(0)
  const [langTab, setLangTab] = useState('fi')

  const [locked, setLocked] = useState(false)
  const toggleLock = () => setLocked(!locked)

  const addQuestion = () => {
    props.setQuestions([...props.questions, {
      id: uuidv4(),
      type: 'select',
      titleFi: '',
      titleEn: '',
      contentFi: '',
      contentEn: '',
      answersFi: ['', ''],
      answersEn: ['', '']
    }])
  }

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const toggleQuestionOptional = (questionIndex) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        return {
          ...question,
          isOptional: !question.isOptional
        }
      }
      return question
    }))
  }

  const toggleQuestionOtherEnabled = (questionIndex) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        return {
          ...question,
          otherEnabled: !question.otherEnabled
        }
      }
      return question
    }))
  }

  const setQuestionType = (questionIndex, type) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        question.type = type

        if (type !== 'text') {
          question.isOptional = false

          // Add empty answers fields if not set
          if (!question.answersFi || !question.answersFi.length) {
            question.answersFi = ['', '']
          }
          if (!question.answersEn || !question.answersEn.length) {
            question.answersEn = ['', '']
          }
        } else {
          // Text answers are always optional
          question.isOptional = true
          question.otherEnabled = false
          // Set answers to empty arrays for type = text
          question.answersFi = []
          question.answersEn = []
        }
      }
      return question
    }))
  }

  const setQuestionTitle = (questionIndex, lang, value) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        question[(lang === 'en' ? 'titleEn' : 'titleFi')] = value
      }
      return question
    }))
  }

  const setQuestionContent = (questionIndex, lang, value) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        question[(lang === 'en' ? 'contentEn' : 'contentFi')] = value
      }
      return question
    }))
  }

  const deleteQuestion = (questionIndex) => {
    const confirm = window.confirm(t('confirm_delete_question'))
    if (confirm) {
      props.setQuestions(props.questions.filter((_, index) => index !== questionIndex))
    }
  }

  const setQuestionAnswer = (questionIndex, answerIndex, lang, value) => {
    props.setQuestions(props.questions.map((question, qIndex) => {
      if (qIndex === questionIndex) {
        const fieldName = lang === 'en' ? 'answersEn' : 'answersFi'

        question[fieldName] = question[fieldName].map((answer, aIndex) => {
          if (aIndex === answerIndex) {
            return value
          }
          return answer
        })
      }
      return question
    }))
  }

  const deleteAnswer = (questionIndex, answerIndex) => {
    const confirm = window.confirm(t('confirm_delete_answer'))
    if (confirm) {
      props.setQuestions(props.questions.map((question, qIndex) => {
        if (qIndex === questionIndex) {
          return {
            ...question,
            answersFi: question.answersFi.filter((_, aIndex) => aIndex !== answerIndex),
            answersEn: question.answersEn.filter((_, aIndex) => aIndex !== answerIndex)
          }
        }
        return question
      }))
    }
  }

  const addAnswer = (questionIndex) => {
    props.setQuestions(props.questions.map((question, index) => {
      if (index === questionIndex) {
        return {
          ...question,
          answersFi: [...question.answersFi, ''],
          answersEn: [...question.answersEn, '']
        }
      }
      return question
    }))
  }

  const moveQuestion = (index, direction) => {
    const newQuestions = [...props.questions];

    if (direction === 'up') {
      if (index === 0) return
      const temp = newQuestions[index - 1]
      newQuestions[index - 1] = newQuestions[index]
      newQuestions[index] = temp
    } else if (direction === 'down') {
      if (index === props.questions.length - 1) return
      const temp = newQuestions[index + 1]
      newQuestions[index + 1] = newQuestions[index]
      newQuestions[index] = temp
    }
    props.setQuestions(newQuestions)
  }

  const renderTabs = () => {
    return (
      <div className={classes.tabsContainer}>
        <div onClick={() => setTab(0)} className={tab === 0 ? classes.tabActive : classes.tab}>{t('basic_info')}</div>
        <div className={classes.tabSpacer} />
        <div onClick={() => setTab(1)} className={tab === 1 ? classes.tabActive : classes.tab}>{t('settings')}</div>
      </div>
    )
  }

  const getCategoryOptions = () => {
    return (props.categories || []).map(item => {
      return {
        label: langTab === 'en' ? (item.titleEn || item.titleFi) : (item.titleFi || item.titleEn),
        value: item.id
      }
    })
  }

  const renderIntroContent = () => {
    if (langTab === 'en') {
      return (
        <>
          <Input
            label={t('poll_title')}
            value={props.titleEn}
            onChange={props.setTitleEn}
            {...validationMessage(props.titleEn || props.titleFi)}
          />
          <div className={classes.categoryContainer}>
            <Select
              label={`${t('poll_category')}`}
              value={props.categoryId}
              options={getCategoryOptions()}
              onChange={props.setCategoryId}
            />
          </div>
          <Input
            label={t('poll_description')}
            value={props.contentEn}
            onChange={props.setContentEn}
            multiline
            {...validationMessage(props.contentEn || props.contentFi)}
          />
        </>
      )
    }
    return (
      <>
        <Input
          label={t('poll_title')}
          value={props.titleFi}
          onChange={props.setTitleFi}
          {...validationMessage(props.titleFi || props.titleEn)}
        />
        <div className={classes.categoryContainer}>
          <Select
            label={`${t('poll_category')}`}
            value={props.categoryId}
            options={getCategoryOptions()}
            onChange={props.setCategoryId}
          />
        </div>
        <Input
          label={t('poll_description')}
          value={props.contentFi}
          onChange={props.setContentFi}
          multiline
          {...validationMessage(props.contentFi || props.contentEn)}
        />
      </>
    )
  }

  const renderQuestions= () => {
    return (
      <div>
        {props.questions.map((question, index) => {
          return (
            <PollQuestionForm
              question={question}
              index={index}
              totalQuestions={props.questions.length}
              moveQuestionUp={() => moveQuestion(index, 'up')}
              moveQuestionDown={() => moveQuestion(index, 'down')}
              deleteQuestion={() => deleteQuestion(index)}
              setTitle={(lang, title) => setQuestionTitle(index, lang, title)}
              setContent={(lang, content) => setQuestionContent(index, lang, content)}
              setType={(type) => setQuestionType(index, type)}
              toggleOptional={() => toggleQuestionOptional(index)}
              toggleOtherEnabled={() => toggleQuestionOtherEnabled(index)}
              setAnswer={(answerIndex, lang, value) => setQuestionAnswer(index, answerIndex, lang, value)}
              addAnswer={() => addAnswer(index)}
              deleteAnswer={(answerIndex) => deleteAnswer(index, answerIndex)}
              submitted={props.submitted}
              locked={locked}
              toggleLock={toggleLock}
              currentLang={langTab}
              setLang={setLangTab}
            />
          )
        })}
        <Button
          text={t('add_question')}
          onClick={addQuestion}
          rightIcon={AddIcon}
          secondary
          variant
        />
      </div>
    )
  }

  const getPollStatus = (lang) => {
    if (lang === 'fi') {
      if (props.titleFi && props.contentFi) return 'green'
      if (props.titleFi || props.contentFi) return 'yellow'
      return 'red'
    } else {
      if (props.titleEn && props.contentEn) return 'green'
      if (props.titleEn || props.contentEn) return 'yellow'
      return 'red'
    }
  }

  const renderLangSelect = () => {
    return (
      <div className={classes.actionButtons}>
        {['fi', 'en'].map(lang => (
          <PollLangButton
            key={lang}
            lang={lang}
            active={langTab === lang}
            onClick={() => setLangTab(lang)}
            status={getPollStatus(lang)}
          />
        ))}
      </div>
    )
  }

  const renderSelectUserTypeVisiblity = () => {
    const toggleType = (type) => {
      let newTypes = props.userTypes || []
      if (newTypes.includes(type)) {
        newTypes = newTypes.filter(val =>  val !== type)
      } else {
        newTypes = [...newTypes, type]
      }

      if (newTypes.length === 0 || newTypes.length === 3) {
        props.setUserTypes(null)
      } else {
        props.setUserTypes(newTypes)
      }
    }

    return (
      <div className={classes.settingsRow}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={!props.userTypes || props.userTypes.length === 0}
            onChange={() => props.setUserTypes(null)}
            label={t('all')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={props.userTypes && props.userTypes.includes('citizen')}
            onChange={() => toggleType('citizen')}
            label={t('citizen')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={props.userTypes && props.userTypes.includes('visitor')}
            onChange={() => toggleType('visitor')}
            label={t('visitor')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={props.userTypes && props.userTypes.includes('business_attendee')}
            onChange={() => toggleType('business_attendee')}
            label={t('business_attendee')}
          />
        </div>
      </div>
    )
  }

  const renderSelectLanguageVisibility = () => {

    return (
      <div className={classes.settingsRow}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={!props.userLang}
            onChange={() => props.setUserLang(null)}
            label={t('all_fi_and_en')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={props.userLang === 'fi'}
            onChange={() => props.userLang === 'fi' ? props.setUserLang(null) : props.setUserLang('fi')}
            label={t('only_fi')}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            checked={props.userLang === 'en'}
            onChange={() => props.userLang === 'en' ? props.setUserLang(null) : props.setUserLang('en')}
            label={t('only_en')}
          />
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (tab === 0) {
      return (
        <>
          <div className={classes.introContainer}>
            <div className={classes.introHeader}>
              <h3 className={classes.title}>{t('poll_introduction')}</h3>
              {renderLangSelect()}
            </div>
            {renderIntroContent()}
          </div>
          <div className={classes.mediaContainer}>
            <MediaInput
              video={props.video}
              setVideo={props.setVideo}
              handleDeleteVideo={props.handleDeleteVideo}
              photo={props.photo}
              handleSetPhoto={props.handleSetPhoto}
              handleRemovePhoto={props.handleRemovePhoto}
              mediaType={props.mediaType}
              setMediaType={props.setMediaType}
            />
          </div>
          {renderQuestions()}
        </>
      )
    }
    if (tab === 1) {
      return (
        <div>
          <Title title={t('poll_visibility_title')} type='subtitle' />
          <p className={classes.settingsDescription}>{t('poll_visibility_description')}</p>
          <p className={classes.settingsDescription}>{t('poll_select_user_types')}</p>
          {renderSelectUserTypeVisiblity()}
          <div style={{ height: '1rem' }} />
          <Title title={t('poll_lang_versions_title')} type='subtitle' />
          <p className={classes.settingsDescription}>{t('poll_lang_versions_description')}</p>
          <p className={classes.settingsDescription}>{t('poll_select_user_languages')}</p>
          {renderSelectLanguageVisibility()}
        </div>
      )
    }
  }

  return (
    <div className={classes.container}>
      {renderTabs()}
      {renderContent()}
    </div>
  )
}

export default PollForm
