import { createContext, useContext } from 'react'
import AppStore from './AppStore'
import SessionStore from './SessionStore'
import UserStore from './UserStore'
import LogStore from './LogStore'
import AnalyticsStore from './AnalyticsStore'
import CouponStore from './CouponStore'
import CouponCampaignStore from './CouponCampaignStore'
import NotificationStore from './NotificationStore'
import PollStore from './PollStore'
import InfluenceStore from './InfluenceStore'

class RootStore {
  appStore
  sessionStore
  userStore
  logStore
  analyticsStore
  entityStore
  couponStore
  notificationStore
  pollStore
  couponCampaignStore
  influenceStore

  constructor() {
    this.appStore = new AppStore(this)
    this.sessionStore = new SessionStore(this)
    this.userStore = new UserStore(this)
    this.logStore = new LogStore(this)
    this.analyticsStore = new AnalyticsStore(this)
    this.couponStore = new CouponStore(this)
    this.couponCampaignStore = new CouponCampaignStore(this)
    this.notificationStore = new NotificationStore(this)
    this.pollStore = new PollStore(this)
    this.influenceStore = new InfluenceStore(this)
  }
}

export const createStore = () => {
  const rootStore = new RootStore()
  return rootStore
}

export const StoreContext = createContext({})
export const StoreProvider = StoreContext.Provider

export const useStore: any = () => useContext(StoreContext)
