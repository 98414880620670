import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { AccessRightsSelector, PageHeader, Title, Button, Input, Select, Dialog, PageContainer, PageContent, PageActions } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'
import { ROLES } from '../../../Constants'
import { formatDateTime } from '../../../Utils/dateTime'
import CouponMultiSelect from '../../../Components/Coupons/CouponMultiselect'
import { sortBy } from 'lodash'

enum Tabs {
  User = 0,
  Account = 1,
}

const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 400,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  boldText: {
    fontWeight: 700
  },
  line: {
    height: 1,
    width: '100%',
    backgroundColor: Colors.lightBorder,
    marginBottom: '2rem'
  },
  accountContainer: {
    padding: '1rem 0'
  },
  organizationSelectContainer: {
    marginTop: '-.5rem'
  }
}))

const User = () => {
  const classes = useStyles()
  const { userStore, sessionStore, couponStore } = useStore()
  const { userId }: any = useParams()

  useEffect(() => {
    userStore.getUser(userId)
    couponStore.getOrganizations()
  }, [])



  console.log(userStore.user)

  const [alertVisible, setAlertVisible] = useState(false)
  const [tab, setTab] = useState(0)
  const [firstName, setFirstName] = useState(get(userStore.user, 'firstName', ''))
  const [lastName, setLastName] = useState(get(userStore.user, 'lastName', ''))
  const [email, setEmail] = useState(get(userStore.user, 'email', ''))
  const [title, setTitle] = useState(get(userStore.user, 'title', ''))
  const [description, setDescription] = useState(get(userStore.user, 'description', ''))
  const [role, setRole] = useState(get(userStore.user, 'role', ''))
  const [accessRights, setAccessRights] = useState(get(userStore.user, 'accessRights', []))
  const [couponOrganizationIds, setCouponOrganizationIds] = useState([])

  useEffect(() => {
    if (userStore.user) {
      setFirstName(get(userStore.user, 'firstName'))
      setLastName(get(userStore.user, 'lastName'))
      setEmail(get(userStore.user, 'email'))
      setTitle(get(userStore.user, 'title'))
      setDescription(get(userStore.user, 'description'))
      setAccessRights(get(userStore.user, 'accessRights'))

      if ((get(userStore.user, 'accessRights') || []).includes('coupon_organization_user')) {
        setRole('coupon_organization_user')
        setCouponOrganizationIds(get(userStore.user, 'couponOrganizationIds', null) || [])
      } else {
        setRole(get(userStore.user, 'role'))
        setAccessRights(get(userStore.user, 'accessRights'))
      }
    }
  }, [userStore.user])


  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)
  const selectTab = (evt, value) => setTab(value)

  const { t } = useTranslation()
  const getTabOptions = () => [
    { id: Tabs.User, label: t('user_details'), description: t('lorem') },
    { id: Tabs.Account, label: t('account_information'), description: t('lorem') }
  ]
  const tabOptions = getTabOptions()

  const getRoles = () => [...ROLES, 'coupon_organization_user'].map((role, index) => ({
    id: index + 1,
    label: t(role),
    value: role
  }))

  const getUpdateText = () => {
    if (tabOptions[tab].id === Tabs.User) return t('update_profile')
    return t('update_account_information')
  }

  const activateProfile = () => {
    closeAlert()
    userStore.updateUser(userStore.user.id, { active: true })
  }

  const deleteProfile = () => {
    closeAlert()
    userStore.updateUser(userStore.user.id, { active: false })
  }

  const updateUser = () => {

    if (role === 'coupon_organization_user') {
      userStore.updateUser(userId, {
        firstName,
        lastName,
        email,
        title,
        description,
        role: 'user',
        accessRights: ['coupon_organization_user'],
        couponOrganizationIds
      })
    } else {
      userStore.updateUser(userId, {
        firstName,
        lastName,
        email,
        title,
        description,
        role,
        accessRights: role === 'admin' ? [] : accessRights,
        couponOrganizationIds: null
      })
    }
  }

  const isActive = get(userStore.user, 'active')

  const getOrganizationOptions = () => {
    return sortBy(couponStore.organizations.map(org => {
      return {
        value: org.id,
        label: org.name
      }
    }), 'label')
  }

  const renderCouponOrganizationSelect = () => {
    if (role !== 'coupon_organization_user') return null
    return (
      <div className={classes.organizationSelectContainer}>
        <Title
          description={t('coupon_organization_user_select_organization')}
          type='subtitle'
        />
        <CouponMultiSelect
          label={t('organizations')}
          options={getOrganizationOptions()}
          onChange={setCouponOrganizationIds}
          value={couponOrganizationIds}
        />
      </div>
    )
  }

  const renderContent = () => {
    const tabId = tabOptions[tab].id
    if (tabId === Tabs.Account) {
      return (
        <>
          <Title title={t('account_information')} description={t('user_account_description')} />
          <div className={classes.accountContainer}>
            <Select
              options={getRoles()}
              label={t('role')}
              value={role}
              onChange={setRole}
              disabled={(get(userStore.user, 'id') === get(sessionStore.user, 'id')) }
            />
            {(role !== 'user') ? null : (
              <AccessRightsSelector
                accessRights={accessRights}
                setAccessRights={setAccessRights}
              />
            )}
            {renderCouponOrganizationSelect()}
          </div>
        </>
      )
    }

    return (
      <>
        <Title title={t('user_details')} description={t('user_details_description')} />
        <Input label={t('first_name')} value={firstName} onChange={setFirstName} />
        <Input label={t('last_name')} value={lastName} onChange={setLastName} />
        <Input label={t('email')} value={email} onChange={setEmail} />
      </>
    )
  }

  const renderDeleteProfile = () => {
    if (tabOptions[tab].id === Tabs.User) {
      // TODO: check if can delete profile
      return (
        <div>
          <div className={classes.line} />
          <Button
            text={isActive ? t('delete_profile') : t('activate_profile')}
            onClick={openAlert}
            secondary
            margin
            fullWidth
          />
        </div>
      )
    }
    return null
  }

  return (
    <>
      <PageHeader
        title={t('user_details')}
        tabs={tabOptions}
        currentTab={tab}
        onTabChange={selectTab}
        backButtonLink='/users'
      />
      <PageContainer>
        <PageContent size='medium' disabled={!get(userStore.user, 'active')}>
          {renderContent()}
        </PageContent>
        <PageActions>
          <p className={classes.text}>
            <span className={classes.boldText}>{t('last_modified')}: </span>
            {formatDateTime(get(userStore, 'user.updatedAt'))}
          </p>
          <Button
            text={getUpdateText()}
            onClick={updateUser}
            margin
            fullWidth
          />
          {renderDeleteProfile()}
        </PageActions>
      </PageContainer>
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={isActive ? deleteProfile : activateProfile}
        title={isActive ? t('delete_profile_title') : t('activate_profile_title')}
        description={isActive ? t('delete_user_profile_description') : t('activate_user_profile_description')}
      />
    </>
  )
}

export default observer(User)
