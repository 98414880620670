import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '..'
import AddIcon from '../../Assets/Icons/add-black.svg'
import EditIcon from '../../Assets/Icons/edit.svg'
import ExcelIcon from '../../Assets/Icons/excel.svg'
import BackButtonIcon from '../../Assets/Icons/back-button-icon.svg'
import { Colors, Fonts } from '../../Utils/theme'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '0 3rem',
    backgroundColor: Colors.orange,
    borderBottom: `1px solid ${Colors.orange}`
  },
  innerRoot: {
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.25rem'
  },
  flex: {
    flex: 1
  },
  title: {
    margin: 0,
    fontFamily: Fonts.heading,
    fontSize: '3rem',
    color: Colors.heading
  },
  subtitle: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.5rem',
    fontWeight: 400,
    color: Colors.black
  },
  cancelActionButton: {
    backgroundColor: Colors.red,
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  activeTabRoot: {
    fontFamily: Fonts.heading,
    fontWeight: 500,
    color: Colors.black,
    fontSize: '1.5rem',
    textTransform: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 0,
    marginRight: '2.5rem',
    maxWidth: 'none'
  },
  tabRoot: {
    fontFamily: Fonts.heading,
    fontWeight: 400,
    color: Colors.black,
    fontSize: '1.5rem',
    textTransform: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 0,
    marginRight: '2.5rem',
    maxWidth: 'none'
  },
  indicator: {
    background: Colors.black,
    height: 4
  },
  backButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1.375rem',
    paddingTop: '1.0275rem',
    width: '3rem'
  },
  backButtonLink: {
    display: 'block',
    flex: 1
  },
  backButton: {
    display: 'flex',
    backgroundColor: Colors.black,
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.5rem',
    width: '2.5rem',
    borderRadius: '2.5rem'
  },
  backButtonIcon: {
    height: '1rem'
  },
  excelButton: {
    marginLeft: '1rem',
    minWidth: '18rem',
    marginTop: '1rem',
    borderColor: Colors.black,
    paddingLeft: '3rem',
    paddingRight: '2rem',
    '&:hover': {
      borderColor: Colors.black
    }
  },
  excelButtonText: {
    fontSize: '1rem',
    color: `${Colors.black} !important`
  }
}))

export default function PageHeader(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderActionButton = () => {
    if (props.onCreateClick) {
      return (
        <Button
          text={props.createText || t('add_new')}
          onClick={props.onCreateClick}
          rightIcon={AddIcon}
          secondary
          variant
        />
      )
    }
    return null
  }

  const renderTabs = () => {
    if (!props.tabs) return null

    return (
      <Tabs
        value={props.currentTab}
        onChange={props.onTabChange}
        classes={{ indicator: classes.indicator }}
        aria-label=''
      >
        {
          props.tabs.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              classes={{ root: tab.id === props.currentTab ? classes.activeTabRoot : classes.tabRoot }}
            />
          ))
        }
      </Tabs>
    )
  }

  const renderBackButton = () => {
    if (props.backButtonLink) {
      return (
        <div className={classes.backButtonContainer}>
          <Link to={props.backButtonLink} className={classes.backButtonLink}>
            <div className={classes.backButton}>
              <img src={BackButtonIcon} className={classes.backButtonIcon} alt='back' />
            </div>
          </Link>
        </div>
      )
    }
    return null
  }

  const renderExcelButton = () => {
    if (props.onExcelButtonPress) {
      return (
        <Button
          text={props.excelButtonText || t('download_results_excel')}
          onClick={props.onExcelButtonPress}
          buttonStyle={classes.excelButton}
          textStyle={classes.excelButtonText}
          leftIcon={ExcelIcon}
          secondary
          excelGreen
        />
      )
    }
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.innerRoot}>
        <div className={classes.titleRow}>
          {renderBackButton()}
          <div>
            <h1 className={classes.title}>{props.title}</h1>
            {props.subtitle && <div className={classes.subtitle}>{props.subtitle}</div>}
          </div>
          <div className={classes.flex} />
          {renderActionButton()}
          {renderExcelButton()}
        </div>
        <div className={classes.bottomRow}>
          {renderTabs()}
        </div>
      </div>
    </div>
  )
}
