import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1.5rem'
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  fiTab: {
    outline: 'none',
    fontSize: '1.125rem',
    borderTopLeftRadius: 5,
    zIndex: 1,
    backgroundColor: Colors.white,
    marginBottom: -1,
    border: `1px solid ${Colors.lightBorder}`,
    borderBottom: `1px solid ${Colors.lightBorder}`,
    padding: `.5rem 1rem`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  fiTabActive: {
    outline: 'none',
    fontSize: '1.125rem',
    fontWeight: 600,
    borderTopLeftRadius: 5,
    zIndex: 1,
    backgroundColor: Colors.white,
    marginBottom: -1,
    border: `1px solid ${Colors.lightBorder}`,
    borderBottom: `1px solid ${Colors.white}`,
    padding: `.75rem 1rem`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  enTab: {
    outline: 'none',
    fontSize: '1.125rem',
    borderTopRightRadius: 5,
    zIndex: 1,
    backgroundColor: Colors.white,
    marginBottom: -1,
    border: `1px solid ${Colors.lightBorder}`,
    borderLeft: 0,
    borderBottom: `1px solid ${Colors.lightBorder}`,
    padding: `.75rem 1rem`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  enTabActive: {
    outline: 'none',
    fontSize: '1.125rem',
    fontWeight: 600,
    borderTopRightRadius: 5,
    zIndex: 1,
    backgroundColor: Colors.white,
    marginBottom: -1,
    border: `1px solid ${Colors.lightBorder}`,
    borderLeft: 0,
    borderBottom: `1px solid ${Colors.white}`,
    padding: `.75rem 1rem`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

  contentContainer: {
    border: `1px solid ${Colors.lightBorder}`,
    padding: `2rem 1rem .5rem`
  },
  redLight: {
    background: Colors.red,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  },
  greenLight: {
    background: Colors.green,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  },
  yellowLight: {
    background: Colors.yellow,
    height: '0.75rem',
    width: '0.75rem',
    borderRadius: '10rem',
    marginLeft: '0.5rem'
  }
}))

export default function Input(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderStatus = (lang) => {
    const status = lang === 'fi' ? props.statusFi : props.statusEn
    if (status === 'red') {
      return <div className={classes.redLight} />
    } else if (status === 'green') {
      return <div className={classes.greenLight} />
    } else if (status === 'yellow') {
      return <div className={classes.yellowLight} />
    }
    return null
  }

  const renderTabs = () => {
    const fiActive = props.currentLanguage === 'fi'
    const enActive = props.currentLanguage === 'en'

    return (
      <div className={classes.tabsContainer}>
        <button
          className={fiActive ? classes.fiTabActive : classes.fiTab}
          onClick={() => props.setLanguage('fi')}
        >
          {t('in_finnish')}
          {renderStatus('fi')}
        </button>
        <button
          className={enActive ? classes.enTabActive : classes.enTab}
          onClick={() => props.setLanguage('en')}
        >
          {t('in_english')}
          {renderStatus('en')}
        </button>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {/*
        // NOTE: No translations
        renderTabs()
      */}
      <div className={classes.contentContainer}>
        {props.content}
      </div>
    </div>
  )
}
