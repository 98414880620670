import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'
import { LangSwitch, LargeImageUpload, Select, Input, OrganizationSelector } from '../../Components'
import Title from '../Common/Title'
import EditorInput from '../Common/EditorInput'

const useStyles = makeStyles((theme) => ({
  topRowContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  topRowLeftContainer: {
    flex: 1
  },
  langSwitchContainer: {
    paddingLeft: '.5rem',
    paddingTop: '.75rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowSpacer: {
    width: '3.4375rem'
  },
  prizeTypeContainer: {
    flex: 7
  },
  numRequiredCodesContainer: {
    flex: 5
  },
  endingLotteryContainer: {
    flex: 1
  },
  lotteryLinkContainer: {
    flex: 3
  },
  downloadLink: {
    padding: '.125rem 0',
    fontSize: '1.125rem',
    fontWeight: 300
  }
}))

const CouponCampaignForm = (props) => {
  const [langTab, setLangTab] = useState('fi')

  const classes = useStyles()
  const { t } = useTranslation()

  const [lotteryLinkEnabled, setLotteryLinkEnabled] = useState(props.lotteryLink ? 1 : 0)
  const [mountedAt] = useState(Date.now())

  useEffect(() => {
    if ((Date.now() - mountedAt) < 1000) {
      if (props.lotteryLink) {
        setLotteryLinkEnabled(1)
      } else {
        setLotteryLinkEnabled(0)
      }
    }
  }, [props.lotteryLink])

  useEffect(() => {
    setLangTab('fi')
  }, [props.disableEnglish])

  const handleSetLotteryLinkEnabled = (value) => {
    props.setLotteryLink('')
    setLotteryLinkEnabled(value)
  }

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const renderName = () => {
    if (langTab === 'fi') {
      return (
        <Input
          label={t('campaign_name')}
          value={props.nameFi}
          onChange={props.setNameFi}
          {...validationMessage(props.nameFi)}
        />
      )
    }
    return (
      <Input
        label={t('campaign_name')}
        value={props.nameEn}
        onChange={props.setNameEn}
        {...validationMessage(props.nameEn)}
      />
    )
  }

  const renderCampaignType = () => {
    // This is disabled for now
    return (
      <Select
        label={t('prize_type')}
        value={props.campaignType}
        onChange={props.setCampaignType}
        options={
          [
            {
              value: 'physical',
              label: t('physical_prize')
            },
            {
              value: 'no_prize',
              label: t('no_prize')
            }
          ]
        }
      />
    )
  }

  const renderNumRequiredCodes = () => {
    const max = 10
    const options = Array(max).fill(null).map((x, index) => ({
      value: index + 1,
      label: `${index + 1}`
    }))

    return (
      <Select
        label={t('amount_of_required_qr_codes_collected')}
        value={props.numRequiredCodes}
        onChange={props.setNumRequiredCodes}
        options={options}
        {...validationMessage(props.numRequiredCodes)}
      />
    )
  }

  const renderTitle = () => {
    if (langTab === 'fi') {
      return (
        <Input
          label={t('title')}
          value={props.titleFi}
          onChange={props.setTitleFi}
          {...validationMessage(props.titleFi)}
        />
      )
    }
    return (
      <Input
        label={t('title')}
        value={props.titleEn}
        onChange={props.setTitleEn}
        {...validationMessage(props.titleEn)}
      />
    )
  }

  const renderHtml = () => {
    if (langTab === 'fi') {
      return (
        <EditorInput
          label={t('description_text')}
          value={props.htmlFi}
          onChange={props.setHtmlFi}
          {...validationMessage(props.htmlFi)}
        />
      )
    }
    return (
      <EditorInput
        label={t('description_text')}
        value={props.htmlEn}
        onChange={props.setHtmlEn}
        {...validationMessage(props.htmlEn)}
      />
    )
  }

  const renderNotificationTitle = () => {
    if (langTab === 'fi') {
      return (
        <Input
          label={t('title')}
          value={props.notificationTitleFi}
          onChange={props.setNotificationTitleFi}
          {...validationMessage(props.notificationTitleFi)}
        />
      )
    }
    return (
      <Input
        label={t('title')}
        value={props.notificationTitleEn}
        onChange={props.setNotificationTitleEn}
        {...validationMessage(props.notificationTitleEn)}
      />
    )
  }

  const renderNotificationHtml = () => {
    if (langTab === 'fi') {
      return (
        <EditorInput
          label={t('message_content')}
          value={props.notificationHtmlFi}
          onChange={props.setNotificationHtmlFi}
          {...validationMessage(props.notificationHtmlFi)}
        />
      )
    }
    return (
      <EditorInput
        label={t('message_content')}
        value={props.notificationHtmlEn}
        onChange={props.setNotificationHtmlEn}
        {...validationMessage(props.notificationHtmlEn)}
      />
    )
  }

  const renderPrizeTitle = () => {
    if (langTab === 'fi') {
      return (
        <Input
          label={t('title')}
          value={props.prizeTitleFi}
          onChange={props.setPrizeTitleFi}
          {...validationMessage(props.prizeTitleFi)}
        />
      )
    }
    return (
      <Input
        label={t('title')}
        value={props.prizeTitleEn}
        onChange={props.setPrizeTitleEn}
        {...validationMessage(props.prizeTitleEn)}
      />
    )
  }

  const renderPrizeContent = () => {
    if (langTab === 'fi') {
      return (
        <Input
          label={t('content')}
          value={props.prizeContentFi}
          onChange={props.setPrizeContentFi}
          {...validationMessage(props.prizeContentFi)}
          maxLength={1024}
          multiline
        />
      )
    }
    return (
      <Input
        label={t('content')}
        value={props.prizeContentEn}
        onChange={props.setPrizeContentEn}
        {...validationMessage(props.prizeContentEn)}
        maxLength={1024}
        multiline
      />
    )
  }

  const renderQrCodeDownload = () => {
    if (props.qrCodeDownloadLink) {
      return (
        <a
          href={props.qrCodeDownloadLink}
          rel='noreferrer'
          target='_blank'
          className={classes.downloadLink}
        >
          {t('download_organizations_qr_codes')}
        </a>
      )
    }
    return null
  }

  const getOrganizationSelectorDeleteWarning = () => {
    if (props.mode === 'create') return null
    return {
      title: t('coupon_campaign_delete_organization_warning_title'),
      content: t('coupon_campaign_delete_organization_warning_description')
    }
  }

  const getStatus = (lang) => {
    const requiredFields = [
      'numRequiredCodes'
    ]

    const requiredLocalizedFields = [
      'name',
      'title',
      'html',
      'notificationTitle',
      'notificationHtml',
      'prizeTitle',
      'prizeContent'
    ]

    const langSuffix = lang === 'fi' ? 'Fi' : 'En'

    let allEmpty = true
    let someEmpty = false

    for (const requiredField of requiredFields) {
      if (get(props, `${requiredField}`, null)) {
        allEmpty = false
      } else {
        someEmpty = true
      }
    }

    for (const requiredLocalizedField of requiredLocalizedFields) {
      if (get(props, `${requiredLocalizedField}${langSuffix}`, null)) {
        allEmpty = false
      } else {
        someEmpty = true
      }
    }

    if (allEmpty) return 'red'
    if (someEmpty) return 'yellow'
    return 'green'
  }

  return (
    <div>
      <div className={classes.topRowContainer}>
        <div className={classes.topRowLeftContainer}>
          <Title
            title={t('coupon_campaign_basic_info')}
            description={t('coupon_campaign_basic_info_description')}
          />
        </div>
        <div className={classes.langSwitchContainer}>
          <LangSwitch
            currentLanguage={langTab}
            setLanguage={setLangTab}
            statusFi={getStatus('fi')}
            statusEn={getStatus('en')}
            disableEn={props.disableEnglish}
          />
        </div>
      </div>
      {renderName()}
      <div className={classes.row}>
        <div className={classes.prizeTypeContainer}>
          {renderCampaignType()}
        </div>
        <div className={classes.rowSpacer} />
        <div className={classes.numRequiredCodesContainer}>
          {renderNumRequiredCodes()}
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.endingLotteryContainer}>
          <Select
            label={t('campaign_ending_lottery')}
            options={[
              {
                label: t('yes'),
                value: 1
              },
              {
                label: t('no'),
                value: 0
              }
            ]}
            onChange={handleSetLotteryLinkEnabled}
            value={lotteryLinkEnabled}
          />
        </div>
        <div className={classes.rowSpacer} />
        <div className={classes.lotteryLinkContainer}>
          <Input
            label={t('lottery_page_link')}
            onChange={props.setLotteryLink}
            value={props.lotteryLink}
            disabled={!lotteryLinkEnabled}
            {...validationMessage(lotteryLinkEnabled ? props.lotteryLink : '-' )}
          />
        </div>
      </div>

      <Title
        title={t('campaign_select_companies')}
        description={t('campaign_select_companies_description')}
        type='subtitle'
      />
      <OrganizationSelector
        organizations={props.organizations}
        organizationIds={props.organizationIds}
        setOrganizationIds={props.setOrganizationIds}
        deleteWarning={getOrganizationSelectorDeleteWarning()}
      />
      {renderQrCodeDownload()}
      <Title
        title={t('campaign_banner_in_app')}
        description={t('campaign_banner_in_app_description')}
        type='subtitle'
      />
      <LargeImageUpload
        photo={props.photo}
        removePhoto={props.removePhoto}
        onFileUpload={props.setPhoto}
      />

      {renderTitle()}
      {renderHtml()}

      <Title
        title={t('campaign_prize_notification_title')}
        description={t('campaign_prize_notification_description')}
        type='subtitle'
      />
      {renderNotificationTitle()}
      {renderNotificationHtml()}

      <Title
        title={t('campaign_prize_view_title')}
        description={t('campaign_prize_view_description')}
        type='subtitle'
      />

      {renderPrizeTitle()}
      {renderPrizeContent()}
    </div>
  )
}

export default CouponCampaignForm
