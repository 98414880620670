import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { CouponOrganizationForm, PageHeader, Button, PageContainer, PageContent, PageActions, Dialog, CouponOrganizationSummary } from '../../../Components'
import { useParams } from 'react-router-dom'
import { Colors } from '../../../Utils/theme'
import { formatDatePeriod } from '../../../Utils/dateTime'

const useStyles = makeStyles((theme) => ({
  line: {
    width: '100%',
    borderBottom: `1px solid ${Colors.lightBorder}`
  }
}))

const CreateCouponOrganization = (props) => {
  const classes = useStyles()

  const { couponStore } = useStore()

  const { organizationId }: any = useParams()

  const [name, setName] = useState(null)
  const [contentFi, setContentFi] = useState(null)
  const [contentEn, setContentEn] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [address, setAddress] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [secondHand, setSecondHand] = useState(false)
  const [url, setUrl] = useState(null)
  const [alwaysVisible, setAlwaysVisible] = useState(false)
  const [altCategoryIds, setAltCategoryIds] = useState([])

  const [photo, setPhoto] = useState(null)
  const [rawPhoto, setRawPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [removePhoto, setRemovePhoto] = useState(false)

  const [alertVisible, setAlertVisible] = useState(false)
  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const { t } = useTranslation()

  useEffect(() => {
    couponStore.getOrganization(organizationId)
    couponStore.getCategories()
    couponStore.getCoupons()
  }, [])

  useEffect(() => {
    if (couponStore.organization) {
      setName(get(couponStore.organization, 'name', null))
      setContentFi(get(couponStore.organization, 'contentFi', null))
      setContentEn(get(couponStore.organization, 'contentEn', null))
      setCategoryId(get(couponStore.organization, 'categoryId', null))
      setAltCategoryIds(get(couponStore.organization, 'altCategoryIds', null) || [])
      setAddress(get(couponStore.organization, 'address', null))
      setLatitude(get(couponStore.organization, 'latitude', null))
      setLongitude(get(couponStore.organization, 'longitude', null))
      setEmail(get(couponStore.organization, 'email', null))
      setPhone(get(couponStore.organization, 'phone', null))
      setSecondHand(!!get(couponStore.organization, 'secondHand'))
      setUrl(get(couponStore.organization, 'url', null))
      setPhoto(get(couponStore.organization, 'photo', null))
      setAlwaysVisible(get(couponStore.organization, 'alwaysVisible', false))
    }
  }, [couponStore.organization])

  const handleSetNewPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setRemovePhoto(false)
      setRawPhoto(null)
    }
  }

  const handleRemovePhoto = (photo) => {
    setNewPhoto(null)
    setPhoto(null)
    setRemovePhoto(true)
    setRawPhoto(null)
  }

  const updateOrganization = () => {
    couponStore.updateOrganization(organizationId, {
      name,
      contentFi,
      contentEn: contentFi, // NOTE: No translations
      categoryId,
      address,
      latitude,
      longitude,
      email,
      phone,
      secondHand,
      url,
      newPhoto,
      removePhoto,
      alwaysVisible,
      altCategoryIds,
      ...(rawPhoto ? { rawPhoto } : {})
    })
  }

  const toggleDelete = () => {
    closeAlert()
    couponStore.updateOrganization(organizationId, { active: !couponStore.organization.active })
  }

  const renderContent = () => {
    return (
      <CouponOrganizationForm
        rawPhoto={rawPhoto}
        setRawPhoto={setRawPhoto}
        name={name}
        setName={setName}
        contentFi={contentFi}
        setContentFi={setContentFi}
        contentEn={contentEn}
        setContentEn={setContentEn}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        categories={couponStore.categories}
        address={address}
        setAddress={setAddress}
        latitude={latitude}
        setLatitude={setLatitude}
        longitude={longitude}
        setLongitude={setLongitude}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        secondHand={secondHand}
        setSecondHand={setSecondHand}
        url={url}
        setUrl={setUrl}
        photo={photo}
        setPhoto={handleSetNewPhoto}
        removePhoto={handleRemovePhoto}
        alwaysVisible={alwaysVisible}
        setAlwaysVisible={setAlwaysVisible}
        altCategoryIds={altCategoryIds}
        setAltCategoryIds={setAltCategoryIds}
      />
    )
  }

  const renderDelete = () => {
    if (couponStore.organization) {
      return (
        <div>
          <div className={classes.line}></div>
          <Button
            text={couponStore.organization.active ? t('delete_organiation') : t('activate_oragnization')}
            onClick={openAlert}
            secondary
            fullWidth
            margin
          />
        </div>
      )
    }
    return null
  }

  const renderDialog = () => {
    const isActive = get(couponStore.organization, 'active')
    let description: any = isActive ? t('delete_coupon_organization_description') : t('activate_coupon_organization_description')
    if (isActive) {
      // Get list of linked coupons
      const linkedCoupons = couponStore.coupons.filter(item => {
        return item.organizationId === Number(organizationId) && item.active
      }).map(item => {
        const title = item.titleFi || item.titleEn
        const dateRange = formatDatePeriod(item.validFrom, item.validUntil)
        return `${title} (${dateRange})`
      })

      const countText = linkedCoupons.length ? `(${linkedCoupons.length} kpl)` : ''

      description = (
        <div>
          <div>{description} {countText}</div>
          <ul>
            {linkedCoupons.map(item => (<li>{item}</li>))}
          </ul>
        </div>
      )
    }
    return (
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={toggleDelete}
        title={isActive ? t('delete_coupon_organization_title') : t('activate_coupon_organization_title')}
        description={description}
      />
    )
  }

  return (
    <>
      <PageHeader
        title={t('edit_organization')}
        backButtonLink='/coupon-organizations'
      />
      <PageContainer>
        <PageContent disabled={!get(couponStore.organization, 'active')} size='small'>
          {renderContent()}
        </PageContent>
        <PageActions>
          <Button text={t('save')} onClick={updateOrganization} margin fullWidth />
          {renderDelete()}
          {renderDialog()}
          <CouponOrganizationSummary
            name={name}
            categoryId={categoryId}
            altCategoryIds={altCategoryIds}
            categories={couponStore.categories}
            address={address}
            email={email}
            phone={phone}
            url={url}
            secondHand={secondHand}
          />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreateCouponOrganization)
