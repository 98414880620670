import { makeStyles } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Title, Input, Button } from '../../Components'
import { observer } from 'mobx-react'

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    paddingTop: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  spacer: {
    width: '2rem'
  }
}))

const CouponCategoryForm = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')

  useEffect(() => {
    if (props.editId) {
      const match = (props.categories || []).find(cat => cat.id === props.editId)
      if (match) {
        setTitleFi(match.titleFi)
        setTitleEn(match.titleEn)
      }
    } else {
      setTitleFi('')
      setTitleEn('')
    }
  }, [props.editId])

  const handleCancel = () => {
    setTitleFi('')
    setTitleEn('')
    props.setEditId(null)
  }

  const handleSave = () => {
    props.onSave({
      titleFi,
      titleEn: titleFi // NOTE: No translations
    })
    setTitleFi('')
    setTitleEn('')
    props.setEditId(null)
  }

  return (
    <>
      <Title
        title={props.editId ? t('edit_category') : t('add_new_category')}
      />
      <Input
        label={t('category_name_fi')}
        value={titleFi}
        onChange={setTitleFi}
      />
      {/*
      <Input
        label={t('category_name_en')}
        value={titleEn}
        onChange={setTitleEn}
      />
      */}
      <div className={classes.buttonsContainer}>
        <Button
          text={props.editId ? t('save_category') : t('create_category')}
          onClick={handleSave}
          disabled={!titleFi} // NOTE: No translations
          fullWidth
        />
        <div className={classes.spacer} />
        <Button
          text={t('cancel')}
          onClick={handleCancel}
          secondary
          fullWidth
        />
      </div>
    </>
  )
}

export default observer(CouponCategoryForm)
