import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { get, sortBy } from 'lodash'
import moment from 'moment'
import { LangTabs, Select, Input, AutocompleteSelect, Title, Checkbox } from '../../Components'
import { Colors, Fonts } from '../../Utils/theme'
import LargeImageUpload from '../Common/LargeImageUpload'
import CouponMultiSelect from './CouponMultiselect'

const useStyles = makeStyles((theme) => ({
  container: {
  },
  createLinkContainer: {
    marginTop: '.125rem',
    marginLeft: '1rem',
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '.25rem',
    border: `1px solid ${Colors.black}`,
    '&:hover': {
      boxShadow: `inset 0 0 0 1px ${Colors.black}`,
    }
  },
  createLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '3.7rem',
    minWidth: '11rem',
    color: Colors.black,
    textDecoration: 'none'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowInputContainer: {
    flex: 1
  },
  rowSpacer: {
    width: '2rem'
  },
  imageContainer: {
    paddingTop: '.5rem'
  },
  verticalSpacer: {
    height: '.5rem'
  },
  inputLabel: {
    fontFamily: Fonts.content,
    color: Colors.content,
    fontSize: '1.125rem',
    marginBottom: '.25rem'
  },
  secondHandSpacer: {
    height: '1rem'
  }
}))

const CouponForm = (props) => {
  const [langTab, setLangTab] = useState('fi')

  const classes = useStyles()
  const { t } = useTranslation()

  const getOrganizationOptions = () => sortBy(props.organizations.filter(org => org.active).map(org => ({
    value: org.id,
    label: org.name
  })), 'label')

  const getCategoryOptions = () => sortBy(props.categories.map(cat => ({
    value: cat.id,
    label: cat.titleFi
  })), 'label')

  const getUseTimesOptions = () => [
    { value: 0, label: t('no_limit') },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' }
  ]

  const getStatus = (lang) => {
    if (lang === 'en') {
      if (!props.titleEn && !props.contentEn) return 'red'
      if (!props.titleEn || !props.contentEn) return 'yellow'
    } else {
      if (!props.titleFi && !props.contentFi) return 'red'
      if (!props.titleFi || !props.contentFi) return 'yellow'
    }
    return 'green'
  }

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const renderCreateCategoryLink = () => {
    if (props.mode !== 'create') return null

    // Check if user is coupon organization user
    const currentUser = props.user
    if (currentUser && currentUser.accessRights && currentUser.accessRights.includes('coupon_organization_user')) {
      return null
    }

    return (
      <div className={classes.createLinkContainer}>
        <Link to='/coupons/add-category' className={classes.createLink}>
          {t('create_category')}
        </Link>
      </div>
    )
  }

  const renderCreateOrganizationLink = () => {
    if (props.mode !== 'create') return null

    // Check if user is coupon organization user
    const currentUser = props.user
    if (currentUser && currentUser.accessRights && currentUser.accessRights.includes('coupon_organization_user')) {
      return null
    }

    return (
      <div className={classes.createLinkContainer}>
        <Link to='/coupons/add-organization' className={classes.createLink}>
          {t('create_organization')}
        </Link>
      </div>
    )
  }

  const renderLangContent = () => {
    if (langTab === 'en') {
      return (
        <>
          <Input
            label={t('coupon_title_en')}
            value={props.titleEn}
            onChange={props.setTitleEn}
            {...validationMessage(props.titleEn)}
          />
          <Input
            label={t('coupon_content_en')}
            value={props.contentEn}
            onChange={props.setContentEn}
            multiline
            {...validationMessage(props.contentEn)}
          />
        </>
      )
    }
    return (
      <>
        <Input
          label={t('coupon_title_fi')}
          value={props.titleFi}
          onChange={props.setTitleFi}
          {...validationMessage(props.titleFi)}
        />
        <Input
          label={t('coupon_content_fi')}
          value={props.contentFi}
          onChange={props.setContentFi}
          multiline
          {...validationMessage(props.contentFi)}
        />
      </>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.rowContainer}>
        <div className={classes.rowInputContainer}>
          <CouponMultiSelect
            label={t('categories')}
            options={getCategoryOptions()}
            onChange={props.setCategoryIds}
            value={props.categoryIds}
            {...validationMessage((props.categoryIds || []).length ? 'ok' : null)}
          />
        </div>
        {renderCreateCategoryLink()}
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.rowInputContainer}>
          <AutocompleteSelect
            label={t('organization')}
            placeholder={t('organization')}
            options={getOrganizationOptions()}
            onChange={props.setOrganizationId}
            value={props.organizationId}
            {...validationMessage(props.organizationId)}
          />
        </div>
        {renderCreateOrganizationLink()}
      </div>
      <div className={classes.inputLabel}>{t('second_hand')}</div>
      <Checkbox
        checked={props.secondHand}
        onChange={() => props.setSecondHand(!props.secondHand)}
        label={t('is_second_hand')}
      />
      <div className={classes.secondHandSpacer} />
      <div className={classes.imageContainer}>
        <LargeImageUpload
          photo={props.photo}
          removePhoto={props.removePhoto}
          onFileUpload={props.setPhoto}
        />
      </div>
      <LangTabs
        currentLanguage={langTab}
        setLanguage={setLangTab}
        content={renderLangContent()}
        statusFi={getStatus('fi')}
        statusEn={getStatus('en')}
      />
      <div className={classes.verticalSpacer} />
      <Title title={t('coupon_using_and_validity')} type='subtitle' />
      <div className={classes.inputRow}>
        <div className={classes.rowInputContainer}>
          <Input
            type='date'
            label={t('valid_from')}
            value={props.validFrom}
            onChange={props.setValidFrom}
            {...validationMessage(props.validFrom)}
          />
        </div>
        <div className={classes.rowSpacer} />
        <div className={classes.rowInputContainer}>
          <Input
            type='date'
            label={t('valid_until')}
            value={props.validUntil}
            onChange={props.setValidUntil}
            {...validationMessage(props.validUntil)}
          />
        </div>
        <div className={classes.rowSpacer} />
        <div className={classes.rowInputContainer}>
          <Select
            label={t('use_times')}
            options={getUseTimesOptions()}
            onChange={props.setUseTimes}
            value={props.useTimes}
          />
        </div>
      </div>
    </div>
  )
}

export default CouponForm
