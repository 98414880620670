import { makeStyles } from '@material-ui/core'
import React from 'react'
import get from 'lodash/get'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  root: {
    paddingTop: '2rem',
    position: 'relative'
  },
  optionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '3.6rem'
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    color: Colors.brandColor2,
    backgroundColor: Colors.white,
    borderRadius: '2rem',
    minHeight: '2rem',
    minWidth: '2rem',
  },
  barContainer: {
    flex: 1,
    borderLeft: '1px solid #afafaf',
    height: '4rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '1rem',
    marginLeft: '.75rem'
  },
  barFilledMax: {
    backgroundColor: Colors.brandColor2,
    height: '1.125rem'
  },
  barFilled: {
    backgroundColor: '#c4c4c4',
    height: '1.125rem'
  },
  percentageMax: {
    color: Colors.black,
    marginLeft: '1rem',
    fontWeight: 700,
    fontSize: '1rem'
  },
  percentage: {
    color: Colors.black50,
    marginLeft: '1rem',
    fontWeight: 700,
    fontSize: '1rem'
  }
}))

export default function PollAnswersChart (props) {
  const classes = useStyles()

  const indexToChar = (index) => String.fromCharCode(65 + parseInt(index || '0', 10))

  const getData = () => {
    let numQuestions = Math.max(
      get(props.question, 'answersFi.length', 0),
      get(props.question, 'answersFi.length', 0)
    )

    const otherEnabled = !!props.question.otherEnabled
    if (otherEnabled) numQuestions = numQuestions + 1

    const totalAnswers = props.answers.length

    return Array(numQuestions).fill(null).map((_, index) => {
      const count = props.answers.filter(ans => {
        if (ans.answerIndex === index) return true
        if ((props.question.otherEnabled && index === numQuestions - 1) && ans.answerIndex === null) return true
        return false
      }).length

      return {
        id: index,
        charCode: indexToChar(index),
        count,
        ratio: totalAnswers ? (count / totalAnswers) : 0
      }
    })
  }

  const renderBar = (item) => {
    const data = getData()
    let max = 0
    for (const item of data) {
      max = Math.max(item.count, max)
    }


    const width = `${Math.round(item.ratio * 85)}%`
    const num = Math.round(item.ratio * 1000) / 10
    return (
      <>
        <div className={item.count === max ? classes.barFilledMax : classes.barFilled} style={{ width }} />
        <span className={item.count === max ? classes.percentageMax : classes.percentage}>{num}%</span>
      </>
    )
  }


  return (
    <div className={classes.root}>
      {getData().map(item => {
        return (
          <div className={classes.optionRow}>
            <div className={classes.option}>
              {item.charCode}
            </div>
            <div className={classes.barContainer}>
              {renderBar(item)}
            </div>
          </div>
        )
      })}
    </div>
  )
}
