import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import { useParams, withRouter } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { Title, PageHeader, Button, CouponForm, CouponUseChart, PageContainer, PageActions, PageContent, Dialog, CouponSummary, CouponUseSummary } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'
import { formatDateTime } from '../../../Utils/dateTime'

const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 400,
    color: Colors.black,
    marginBottom: '1.5rem'
  },
  boldText: {
    fontWeight: 700
  },
  line: {
    width: '100%',
    borderBottom: `1px solid ${Colors.lightBorder}`
  },
  topRow: {
    position: 'relative'
  },
  copyContainer: {
    position: 'absolute',
    top: '.75rem',
    right: 0,
    background: Colors.white
  }
}))

const Coupon = (props) => {
  const classes = useStyles()
  const { sessionStore, couponStore } = useStore()
  const { couponId }: any = useParams()

  useEffect(() => {
    couponStore.getCoupon(couponId)
    couponStore.getOrganizations()
    couponStore.getCategories()
    couponStore.getCouponAnalytics(couponId)
    couponStore.getCouponViews(couponId)
  }, [])

  useEffect(() => {
    if (couponStore.coupon) {
      setTitleFi(get(couponStore.coupon, 'titleFi', null))
      setTitleEn(get(couponStore.coupon, 'titleEn', null))
      setContentFi(get(couponStore.coupon, 'contentFi', null))
      setContentEn(get(couponStore.coupon, 'contentEn', null))
      setUseTimes(get(couponStore.coupon, 'useTimes', null))
      setSecondHand(!!get(couponStore.coupon, 'secondHand'))
      setValidFrom(get(couponStore.coupon, 'validFrom', null) ? moment(couponStore.coupon.validFrom).format('YYYY-MM-DD') : null)
      setValidUntil(get(couponStore.coupon, 'validUntil', null) ? moment(couponStore.coupon.validUntil).format('YYYY-MM-DD') : null)
      setOrganizationId(get(couponStore.coupon, 'organization.id', null))
      setPhoto(get(couponStore.coupon, 'photo', null))

      // Handle category ids
      let newCategoryIds = get(couponStore.coupon, 'categoryIds') || []
      if (!newCategoryIds.length && get(couponStore.coupon, 'category.id', null)) {
        // Fallback to legacy category id
        newCategoryIds = [couponStore.coupon.category.id]
      }
      setCategoryIds(newCategoryIds)
    }
  }, [couponStore.coupon])

  const [tab, setTab] = useState(0)

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [contentFi, setContentFi] = useState(null)
  const [contentEn, setContentEn] = useState(null)
  const [useTimes, setUseTimes] = useState(null)
  const [secondHand, setSecondHand] = useState(false)
  const [validFrom, setValidFrom] = useState(null)
  const [validUntil, setValidUntil] = useState(null)
  const [organizationId, setOrganizationId] = useState(null)
  const [categoryIds, setCategoryIds] = useState([])

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [removePhoto, setRemovePhoto] = useState(false)

  const [alertVisible, setAlertVisible] = useState(false)
  const openAlert = () => setAlertVisible(true)
  const closeAlert = () => setAlertVisible(false)

  const selectTab = (evt, value) => setTab(value)
  const handleSetValidFrom = (val) => setValidFrom(val)
  const handleSetValidUntil = (val) => setValidUntil(val)

  const handleSetNewPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setRemovePhoto(false)
    }
  }

  const handleRemovePhoto = (photo) => {
    setNewPhoto(null)
    setPhoto(null)
    setRemovePhoto(true)
  }

  const { t } = useTranslation()

  const copyCoupon = () => {
    couponStore.setCopyFields({
      titleFi,
      titleEn: titleFi, // NOTE: No translations
      contentFi,
      contentEn: contentFi, // NOTE: No translations,
      secondHand,
      useTimes,
      photo,
      categoryIds,
      validFrom,
      validUntil,
      organizationId
    })

    setTimeout(() => {
      props.history.push('/coupons/add')
    }, 100)
  }

  const updateCoupon = () => {
    couponStore.updateCoupon(couponId, {
      titleFi,
      titleEn: titleFi, // NOTE: No translations
      contentFi,
      contentEn: contentFi, // NOTE: No translations
      useTimes,
      secondHand,
      validFrom: validFrom ? moment(validFrom).startOf('day').toISOString() : validFrom,
      validUntil: validUntil ? moment(validUntil).endOf('day').toISOString() : validUntil,
      organizationId,
      categoryId: (categoryIds && categoryIds.length) ? categoryIds[0] : null,
      categoryIds,
      newPhoto,
      removePhoto
    })
  }

  const toggleDelete = () => {
    closeAlert()
    couponStore.updateCoupon(couponId, { active: !couponStore.coupon.active })
  }

  const renderDelete = () => {
    if (couponStore.coupon) {
      return (
        <div>
          <div className={classes.line}></div>
          <Button
            text={couponStore.coupon.active ? t('delete_coupon') : t('activate_coupon')}
            onClick={openAlert}
            secondary
            fullWidth
            margin
          />
        </div>
      )
    }
    return null
  }

  const renderCopyButton = () => {
    return (
      <div className={classes.copyContainer}>
        <Button
          text={t('copy_offer_as_template')}
          onClick={copyCoupon}
          secondary
          small
        />
      </div>
    )
  }

  const getExcelButtonAction = () => {
    if (couponStore.couponAnalytics) {
      const totalUses = get(couponStore.couponAnalytics, 'usesByMonth', []).reduce((partialSum, item) => partialSum + item.count, 0)
      if (totalUses) {
        return () => couponStore.exportCouponUseCsv(couponId)
      }
    }
    return null
  }

  const renderCoupon = () => {
    return (
      <>
        <PageContent size='small' disabled={!get(couponStore.coupon, 'active')}>
          <div className={classes.topRow}>
            <Title title={t('coupon_basic_details')} />
            {renderCopyButton()}
          </div>
          <CouponForm
            validFrom={validFrom}
            setValidFrom={handleSetValidFrom}
            validUntil={validUntil}
            setValidUntil={handleSetValidUntil}
            titleEn={titleEn}
            setTitleEn={setTitleEn}
            contentEn={contentEn}
            setContentEn={setContentEn}
            titleFi={titleFi}
            setTitleFi={setTitleFi}
            contentFi={contentFi}
            setContentFi={setContentFi}
            organizations={couponStore.organizations}
            categories={couponStore.categories}
            organizationId={organizationId}
            setOrganizationId={setOrganizationId}
            categoryIds={categoryIds}
            setCategoryIds={setCategoryIds}
            useTimes={useTimes}
            setUseTimes={setUseTimes}
            secondHand={secondHand}
            setSecondHand={setSecondHand}
            photo={photo}
            setPhoto={handleSetNewPhoto}
            removePhoto={handleRemovePhoto}
            submitted={true}
            user={get(sessionStore, 'user', null)}
          />
        </PageContent>
        <PageActions>
          <p className={classes.text}>
            <span className={classes.boldText}>{t('last_modified')}: </span>
            {formatDateTime(get(couponStore.coupon, 'updatedAt'))}
          </p>
          <Button
            text={t('save')}
            onClick={updateCoupon}
            margin
            fullWidth
          />
          {renderDelete()}
          <CouponSummary
            titleFi={titleFi}
            titleEn={titleEn}
            contentFi={contentFi}
            contentEn={contentEn}
            useTimes={useTimes}
            validFrom={validFrom}
            validUntil={validUntil}
            organizationId={organizationId}
            organizations={couponStore.organizations}
            categoryIds={categoryIds}
            categories={couponStore.categories}
            secondHand={secondHand}
          />
        </PageActions>
      </>
    )
  }

  const renderAnalytics = () => {
    if (couponStore.couponAnalytics) {
      const totalUses = get(couponStore.couponAnalytics, 'usesByMonth', []).reduce((partialSum, item) => partialSum + item.count, 0)
      return (
        <>
          <PageContent>
            <CouponUseSummary
              couponUses={totalUses}
              couponViews={couponStore.couponViews || 0}
            />
            <CouponUseChart
              monthData={couponStore.couponAnalytics.usesByMonth}
              weekData={couponStore.couponAnalytics.usesByWeek}
            />
          </PageContent>
        </>
      )
    }
    return null
  }

  return (
    <>
      <PageHeader
        title={t('edit_coupon')}
        tabs={[
          { id: 0, label: t('coupon_details') },
          { id: 1, label: t('analytics') }
        ]}
        onExcelButtonPress={getExcelButtonAction()}
        excelButtonText={t('download_uses_excel')}
        currentTab={tab}
        onTabChange={selectTab}
        backButtonLink='/coupons'
      />
      <PageContainer>
        {tab === 0 ? renderCoupon() : renderAnalytics()}
      </PageContainer>
      <Dialog
        open={alertVisible}
        handleClose={closeAlert}
        handleOk={toggleDelete}
        title={get(couponStore.coupon, 'active') ? t('delete_coupon_title') : t('activate_coupon_title')}
        description={get(couponStore.coupon, 'active') ? t('delete_coupon_description') : t('activate_coupon_description')}
      />
    </>
  )
}

export default withRouter(observer(Coupon))
