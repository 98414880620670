import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Input, Button } from '../../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '41.25rem'
  },
  description: {
    marginBottom: '3rem'
  },
  backLink: {
    textAlign: 'center',
    display: 'block',
    marginTop: '2rem'
  }
}))

const VerifyEmail = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore } = useStore()

  let initialCode = ''
  if (window.location.search) {
    const parts = window.location.search.split('code=')
    if (parts.length > 1 && parts[1].split('&')[0].length === 36) {
      initialCode = parts[1].split('&')[0]
    }
  }

  useEffect(() => {
    if (initialCode) {
      sessionStore.getUserInvite(initialCode)
    }
  }, [])

  const [password, setPassword] = useState('')

  const verifyUserInvite = () => {
    sessionStore.verifyUserInvite(initialCode, password)
    setPassword('')
  }

  return (
    <div className={classes.root}>
      <Title
        title={t('verify_email_address')}
        description={initialCode && !!sessionStore.emailVerification ? t('verify_email_description') : t('invalid_or_expired_code_description')}
        type='main'
        descriptionStyle={classes.description}
      />
      { initialCode && !!sessionStore.emailVerification && (
        <Input
          label={t('password')}
          value={password}
          onChange={setPassword}
          type='password'
          autoFocus
        />
      )}
      {initialCode && !!sessionStore.emailVerification && <Button text={t('verify')} onClick={verifyUserInvite} disabled={!password || password.length < 8} margin />}
      <Link className={classes.backLink} to='/'>{t('back_to_login_page')}</Link>
    </div>
  )
}

export default observer(VerifyEmail)
