import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import * as get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, Fonts } from '../../Utils/theme'
import { formatDatePeriod } from '../../Utils/dateTime'
import { withRouter } from 'react-router'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.tableRowBackgroundColor,
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: `${Colors.tableRowBackgroundHover} !important`
    }
  },
  deleted: {
    border: `1px solid ${Colors.red}`,
    opacity: 0.5
  },
  truncated: {
    maxWidth: '16rem',
    maxHeight: '4em',
    overflow: 'hidden'
  },
  cell: {
    fontSize: '1.125rem',
    fontWeight: 300,
    height: '4rem',
    color: Colors.tableContentColor,
    borderBottom: 'none'
  },
  firstCell: {
    fontWeight: 500,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  lastCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    fontWeight: 300
  },
  categoryBadge: {
    fontFamily: Fonts.content,
    background: Colors.categoryBadgeBackground,
    color: Colors.categoryBadgeText,
    fontSize: '0.875rem',
    textAlign: 'center',
    display: 'inline-block',
    borderRadius: '.3125rem',
    padding: '.5rem .75rem',
    fontWeight: 400,
    margin: '.25rem'
  },
  eventBadge: {
    background: Colors.brandColor1,
    color: Colors.white,
    textAlign: 'center',
    display: 'inline-block',
    borderRadius: 5,
    padding: '.5rem .75rem',
    fontSize: '1rem',
    margin: '.25rem'
  }
}))

function CouponTableRow (props) {
  const { item } = props
  const classes = useStyles()

  const { t } = useTranslation()

  const handleClick = () => props.history.push(`/coupons/${item.id}`)

  const statusClass = item.active ? '' : classes.deleted
  return (
    <TableRow classes={{ root: [classes.root, statusClass].join(' ') }} hover>
      <TableCell onClick={handleClick} className={[classes.cell, classes.firstCell].join(' ')}>
        <div className={classes.truncated}>
          <span>{get(item, 'organization.name')}</span>
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={[classes.cell].join(' ')}>
        <div className={classes.truncated}>
          <span>{item.titleFi || item.titleEn}</span>
        </div>
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>
        {get(item, 'totalUseCount')}
      </TableCell>
      <TableCell onClick={handleClick} className={classes.cell}>
        {get(item, 'categoryNames', []).map(cat => {
          return <div key={cat} className={classes.categoryBadge}>{cat}</div>
        })}
      </TableCell>
      <TableCell
        onClick={handleClick}
        className={[classes.cell, classes.lastCell].join(' ')}
      >
        {formatDatePeriod(item.validFrom, item.validUntil)}
      </TableCell>
    </TableRow>
  )
}

export default withRouter(CouponTableRow)
